import { Clock } from 'react-feather';
import { Book, Car, Cards, Logout, Phone, Report, Settings, Users } from 'tabler-icons-react';

export const Items = [
    {
        title: "Звонки",
        isAdmin: false,
        icon: <Phone size={24} className="menu-icon"  />,
        href: "/call",
    },
    {
        title: "Учет времени",
        isAdmin: false,
        icon: <Clock size={24} className="menu-icon"  />,
        href: "/time-report",
    },
    {
        title: "Занятость по задачам",
        isAdmin: false,
        icon: <Report size={24} className="menu-icon"  />,
        href: "/occupancy-report",
    },
    {
        title: "Справочники",
        isAdmin: true,
        icon: <Book size={24} className="menu-icon"  />,
        subItems: [
            {
                title: "АТС",
                href: "/atc"
            },
            {
                title: "Направления",
                href: "/direction"
            }
        ]
    },

    {
        title: "Настройки",
        isAdmin: true,
        icon: <Settings size={24} className="menu-icon"  />,
        subItems: [
            {
                title: "Задачи",
                href: "/task-settings"
            },
            {
                title: "Настройки для occupancy",
                href: "/occupancy-settings"
            },
            
        ]
    },

    {
        title: "Учетные записи",
        isAdmin: true,
        icon: <Users size={24} className="menu-icon"  />,
        href: "/user",
    },

    
    
    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
    },

]