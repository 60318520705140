export class Atc {
     
    private id: string; 
    private name: string; 
    private url: string;
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, name: string, url: string, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.name = name;
        this.url = url;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
    public Url = (): string => {
        return this.url
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}