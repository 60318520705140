import { useEffect, useState } from "react";
import { ReportDopGroupCalcFields, ReportOperatorDayStatResponse } from "../../../../repository/api/generated/timeApi"
import { Minus, Plus } from "tabler-icons-react";

export const DetalizationTable = (props: { list: { [key: string]: ReportDopGroupCalcFields;}, oneDayReport: boolean  }) => {
    const getLoginsSortedInNameSlice = () => {
        let names: string[] = [];
        Object.keys(props.list).map((login, index) => {
            names.push(props.list[login].day_stats![0].fio!)
        })

        names.sort()

        let loginsOrdered: string[] = [];

        let tmpLogins: string[] = Object.keys(props.list)

        names.map((fio, index) => {
            for (let index = 0; index < tmpLogins.length; index++) {
                const login = tmpLogins[index];
                const operator = props.list[login];
                if (operator.day_stats![0].fio! == fio) {
                    loginsOrdered.push(operator.day_stats![0].login!)
                    break
                }
                
            }
        })

        return loginsOrdered
    }

    return (
        <div className="accordion" style={{ padding: 0 }}>
            {
                props.oneDayReport?<OneDayReport list={props.list} loginsOrdered={getLoginsSortedInNameSlice()} />:getLoginsSortedInNameSlice().map((login, index) => {
                    return <div className="card accordion-item" style={{ width: "98.5%" }}>
                        <OperatorSeveralDaysTable groupInfo={props.list[login]}  />
                    </div>
                }
                )
            }
        </div>
    )
}

const OperatorSeveralDaysTable = (props: { groupInfo: ReportDopGroupCalcFields }) => {
    const [collapsed, setCollapsed] = useState(true)

    const formatFio = (input: string) => {
        let items = input.split(' ')
        if (items.length < 2) {
            return input
        }
        return items[0] + " " + items[1]
    }

    const formatDateMonthAndDay = (data: string) => {
        let day = new Date(data.replace("Z", ""))
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm;
    }

    const formatDateHourAndMinutes = (data: string) => {
        let day = new Date(data.replace("Z", ""))
        let hh = day.getHours().toString();
        let mins = day.getMinutes().toString();

        if (hh.length == 1) hh = '0' + hh;
        if (mins.length == 1) mins = '0' + mins;

        return hh + ':' + mins;
    }

    const fancyTimeFormat = (duration: number) => {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        return ret;
    }

    return <div>
        <div className="table-responsive text-nowrap" >
            <table className="table mt-1" onClick={() => setCollapsed(!collapsed)} style={{ cursor: "pointer" }}>
                <tbody className="table table-hover">
                    <td style={{ width: "2%" }}>{collapsed ? <span style={{ height: 20}}>+</span> : <span style={{ height: 20}}>-</span>}</td>
                    <td style={{ width: 0, padding: 10, fontSize: 13 }}>{props.groupInfo.day_stats![0].fio!}</td>
                    <td style={{ width: "5.5%", padding: 10 }}><b>{props.groupInfo.post!.toFixed()}</b></td>
                    <td style={{ width: "9%", padding: 10 }}><b>{props.groupInfo.utilization!.toFixed()}</b></td>
                </tbody>
            </table>
        </div>

        <div className="table-responsive text-nowrap" style={{display: collapsed ? "none":""}}>
            <table className="table mt-1" style={{ width: "98%" }}>
                <thead>
                    <tr>
                        <th style={{ width: 0, fontWeight: 400 }}>Дата</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Старт</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Выход</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Онлайн</th>
                        <th style={{ width: 0, fontWeight: 400 }}>На месте</th>
                        <th style={{ width: 0, fontWeight: 400 }}>В вызове</th>
                        <th style={{ width: 0, fontWeight: 400 }}>В пост</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Перерывы</th>
                        <th style={{ width: 0, fontWeight: 400 }}>План</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Обед</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Обуч</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Техн</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Доп. з</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Прочее</th>
                        <th style={{ width: 0, fontWeight: 400 }}>%Пост</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Util</th>
                    </tr>
                </thead>
                <tbody className="table table-hover">
                    {
                        props.groupInfo.day_stats && props.groupInfo.day_stats!.map((dayStat, index) => <tr>
                            <td>{formatDateMonthAndDay(dayStat.data!)}</td>
                            <td>{formatDateHourAndMinutes(dayStat.start!)}</td>
                            <td>{formatDateHourAndMinutes(dayStat.exit_call_center!)}</td>
                            <td>{fancyTimeFormat(dayStat.sum_of_online_and_breaks!)}</td>
                            <td>{fancyTimeFormat(dayStat.on_line!)}</td>
                            <td>{fancyTimeFormat(dayStat.in_call!)}</td>
                            <td>{fancyTimeFormat(dayStat.in_post!)}</td>
                            <td>{fancyTimeFormat(dayStat.sum_of_breaks!)}</td>
                            <td>{fancyTimeFormat(dayStat.planned_break!)}</td>
                            <td>{fancyTimeFormat(dayStat.dinner_break!)}</td>
                            <td>{fancyTimeFormat(dayStat.study_break!)}</td>
                            <td>{fancyTimeFormat(dayStat.tech_break!)}</td>
                            <td>{fancyTimeFormat(dayStat.dop_tasks_break!)}</td>
                            <td>{fancyTimeFormat(dayStat.other_break!)}</td>
                            <td>{dayStat.post_percent!.toFixed()}</td>
                            <td>{dayStat.utilization!.toFixed()}</td>
                        </tr>
                        )
                    }
                </tbody>
            </table>
        </div>

    </div>
}

const OneDayReport = (props: { list: { [key: string]: ReportDopGroupCalcFields;}, loginsOrdered: string[] }) => {

    const formatFio = (input: string) => {
        let items = input.split(' ')
        if (items.length < 2) {
            return input
        }
        return items[0] + " " + items[1]
    }

    const formatDateMonthAndDay = (data: string) => {
        let day = new Date(data.replace("Z", ""))
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm;
    }

    const formatDateHourAndMinutes = (data: string) => {
        let day = new Date(data.replace("Z", ""))
        let hh = day.getHours().toString();
        let mins = day.getMinutes().toString();

        if (hh.length == 1) hh = '0' + hh;
        if (mins.length == 1) mins = '0' + mins;

        return hh + ':' + mins;
    }

    const fancyTimeFormat = (duration: number) => {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        return ret;
    }

    return <div>

        <div className="table-responsive text-nowrap">
            <table className="table mt-1" style={{ width: "98%" }}>
                <thead>
                    <tr>
                        <th style={{ width: 0, fontWeight: 400 }}>Оператор</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Старт</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Выход</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Онлайн</th>
                        <th style={{ width: 0, fontWeight: 400 }}>На месте</th>
                        <th style={{ width: 0, fontWeight: 400 }}>В вызове</th>
                        <th style={{ width: 0, fontWeight: 400 }}>В пост</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Перерывы</th>
                        <th style={{ width: 0, fontWeight: 400 }}>План</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Обед</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Обуч</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Техн</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Доп. з</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Прочее</th>
                        <th style={{ width: 0, fontWeight: 400 }}>%Пост</th>
                        <th style={{ width: 0, fontWeight: 400 }}>Util</th>
                    </tr>
                </thead>
                <tbody className="table table-hover">
                    {
                        props.loginsOrdered.map((login, index) => {
                            return <tr>
                            <td>{formatFio(props.list[login].day_stats![0].fio!)}</td>
                            <td>{formatDateHourAndMinutes(props.list[login].day_stats![0].start!)}</td>
                            <td>{formatDateHourAndMinutes(props.list[login].day_stats![0].exit_call_center!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].sum_of_online_and_breaks!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].on_line!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].in_call!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].in_post!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].sum_of_breaks!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].planned_break!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].dinner_break!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].study_break!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].tech_break!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].dop_tasks_break!)}</td>
                            <td>{fancyTimeFormat(props.list[login].day_stats![0].other_break!)}</td>
                            <td>{props.list[login].day_stats![0].post_percent!.toFixed()}</td>
                            <td>{props.list[login].day_stats![0].utilization!.toFixed()}</td>
                        </tr>})
                    }
                </tbody>
            </table>
        </div>
    </div>
}