export enum UpdateActionTypes {
    UPDATE_REQUEST_SEND = 'update_request_send',
    UPDATE_ERROR = 'update_error',
    UPDATE_SUCCESS = 'update_success',
    
    UPDATE_CREATE_REQUEST = 'update_create_request',
    UPDATE_CREATE_SUCCESS = 'update_create_success',
    UPDATE_CREATE_ERROR = 'update_create_error',

    UPDATE_DELETE_REQUEST = 'update_delete_request',
    UPDATE_DELETE_SUCCESS = 'update_delete_success',
    UPDATE_DELETE_ERROR = 'update_delete_error',

    UPDATE_UPDATE_REQUEST = 'update_update_request',
    UPDATE_UPDATE_SUCCESS = 'update_update_success',
    UPDATE_UPDATE_ERROR = 'update_update_error',
}