import { Update } from "../../domain/occupancy_service/update/update";
import { UpdateActionTypes } from "../action-types";
import { UpdateActions } from "../actions";
import produce from 'immer';


interface UpdateState {
    loading: boolean | null;
    updates: Update[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: UpdateState = {
    loading: null,
    updates: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: UpdateState = initialState, action: UpdateActions) => {
    switch (action.type) {
        case UpdateActionTypes.UPDATE_REQUEST_SEND:
            state.loading = true;
            return state;
        case UpdateActionTypes.UPDATE_SUCCESS:
            state.loading = false;
            state.updates = action.payload;
            return state;
        case UpdateActionTypes.UPDATE_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case UpdateActionTypes.UPDATE_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case UpdateActionTypes.UPDATE_CREATE_SUCCESS:
            state.entityLoading = false
            state.updates!.push(action.payload)
            state.entityError = ""
            return state;
        case UpdateActionTypes.UPDATE_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case UpdateActionTypes.UPDATE_DELETE_SUCCESS:
            state.updates = state.updates!.filter((updates) => updates.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
