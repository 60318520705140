import { CallsRepository } from "../../repository/api/call/call";
import { actionCreators } from "../../state";

export class CallUseCases {
    private api: CallsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: CallsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadFiltredCalls = async (offset: number, limit: number, from: Date, to: Date, entityId: string, taskName: string[], callType: string, operatorName: string[], pipelineId:string, leadStage: string) => {
        this.stateActions.SendCallListRequest()
        let response = await this.api.ReadCallsFiltred(offset, limit, from, to, entityId, taskName, callType, operatorName, pipelineId, leadStage)
        if (response instanceof Error) {
            this.stateActions.CallListError(response.message)
            return
        } else {
            this.stateActions.CallListSuccess(response.count, response.list)
        }
    }

    public GetAudioLocalLink = async (url: string) => {
        let response = await this.api.GetAudioLocalLink(url)
        return response
    }

    public GetStatusesList = async () => {
        let response = await this.api.GetStatusesList()
        return response
    }

    public GetOperatorsList = async () => {
        let response = await this.api.GetOperatorsList()
        return response
    }

    public GetTasksList = async () => {
        let response = await this.api.GetTasksList()
        return response
    }

    public DownloadXlsx = async (from: Date, to: Date, entityId: string, taskName: string[], callType: string, operatorName: string[], pipelineId: string, leadStage: string) => {
        let response = await this.api.DownloadXlsx(from, to, entityId, taskName, callType, operatorName, pipelineId, leadStage)
        return response
    }

}
