import axios from "axios";
import { Settings } from "../../../../domain/occupancy_service/settings/settings";
import { Configuration, SettingsApi, SettingsSettingsResponse } from "../../generated/occupancyApi";

export class SettingsRepository {
    private service: SettingsApi;

    constructor() {
        this.service = new SettingsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadSettings(): Promise<Settings | Error> {
        try {
            let response = await this.service.apiOccupancySettingsGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainSettings(response.data)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateSettings(occupancyCheckPeriodInMinutes: number,): Promise<Settings | Error> {
        try {
            let response = await this.service.apiOccupancySettingsPost({ occupancy_check_period_in_minutes: occupancyCheckPeriodInMinutes, }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
                let settings = this.toDomainSettings(response.data);
                return settings
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    
    toDomainSettings(settings: SettingsSettingsResponse): Settings {
        return new Settings( settings.occupancy_check_period_in_minutes!, new Date(settings.modified_at!), );
    }
    
}