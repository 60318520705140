import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../../context/useCases";
import { FiledType, Form } from "../../../../components/form";
import { Menu } from "../../../../components/menu";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";

export const OccupancySettingsEdit = () => {

    let useCases = useContext(UseCasesContext)

    const [form, setForm] = useState<{
        occupancyCheckPeriodInMinutes: number,
    }>({
        occupancyCheckPeriodInMinutes: 30,
    })

    const settings = useTypedSelector(({ settings }) => {
        return settings
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        useCases?.settingsUseCase.CreateSettings(form.occupancyCheckPeriodInMinutes!, () => { })
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    useEffect(()=>{
        useCases?.settingsUseCase.ReadSettings()
    },[])

    useEffect(()=>{
        if (settings) {
            setForm({
                occupancyCheckPeriodInMinutes: settings.settings?.OccupancyCheckPeriodInMinutes()!
            })
        }
    },[settings?.settings])


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4">Настройки для сервиса занятости операторов</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Настройки для сервиса занятости операторов</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "occupancyCheckPeriodInMinutes",
                                            title: "Интервал проверки Occupancy в минутах",
                                            placeholder: "Интервал проверки Occupancy в минутах",
                                            value: form.occupancyCheckPeriodInMinutes,
                                            type: FiledType.Number,
                                        },
                                    ]}

                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}