import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import Paginator from "../../../components/paginator/paginator"
import Select from "react-select";
import Multiselect from 'multiselect-react-dropdown';
import { LeadgenStatus } from "../../../../repository/api/generated/callApi"

export const CallList = () => {
    let { id } = useParams();
    const [page, setPage] = useState(0)
    const [countOnPage, setCountOnPage] = useState(50)

    const [pipeline, setPipeline] = useState(0)
    const [pipelinesList, setPipelinesList] = useState<{ name: string, id: number }[]>([])
    const [allStatuses, setAllStatuses] = useState<LeadgenStatus[]>([])
    const [statusesNamesMap, setStatusesNamesMap] = useState<Map<string, string>>(new Map())
    const [onlyStatusesMap, setOnlyStatusesMap] = useState<Map<number, string>>(new Map())
    const [statusesList, setStatusesList] = useState<{ name: string, id: number, pipelineId: number, pipelineName: string }[]>([])

    const [operatorOptions, setOperatorOptions] = useState<string[]>([])
    const [taskNameOptions, setTaskNameOptions] = useState<string[]>([])

    const [pagination, setPagination] = useState<{ offset: number, limit: number }>({
        offset: 0,
        limit: countOnPage,
    })

    const [filter, setFilter] = useState<{
        from: Date,
        to: Date,
        entityId: string,
        taskName: string[],
        callType: "" | "inbound" | "outbound",
        operatorName: string[],
        pipelineId: number,
        leadStage: number,
    }>({
        from: new Date(),
        to: new Date(),
        entityId: "",
        taskName: [],
        callType: "",
        operatorName: [],
        pipelineId: 0,
        leadStage: 0,
    })



    let useCases = useContext(UseCasesContext)


    const calls = useTypedSelector(({ call }) => {
        return call
    })

    useEffect(() => {
        let offset = page * countOnPage
        if (pagination) {
            setPagination({
                ...pagination,
                offset: offset,
            })

            useCases?.callUseCase.ReadFiltredCalls(offset, pagination.limit, filter.from, filter.to, filter.entityId, filter.taskName, filter.callType, filter.operatorName, filter.pipelineId.toString(), filter.leadStage.toString())
        }

    }, [page])

    useEffect(() => {
        if (pagination) {
            setPagination({
                ...pagination,
                offset: 0,
                limit: countOnPage!,
            })
        }
    }, [countOnPage])

    useEffect(() => {
        useCases?.callUseCase.ReadFiltredCalls(pagination.offset, pagination.limit, filter.from, filter.to, filter.entityId, filter.taskName, filter.callType, filter.operatorName, filter.pipelineId.toString(), filter.leadStage.toString())
    }, [filter])

    const formatDateForInput = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (filter) {
            setFilter({
                ...filter,
                [e.target.name]: new Date(e.target.value)
            })
        }
    }

    const inputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        let value: any = e.target.value
        if (e.target.type === "number") {
            if (e.target.value === "") {
                value = 0
            } else {
                value = parseInt(e.target.value)
            }
        }
        setFilter({
            ...filter,
            [e.target.name]: value,
        })
    }


    const inputChangeStatus = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        let value: any = e.target.value
        
        if (e.target.value === "") {
            value = 0
            setFilter({
                ...filter,
                pipelineId: 0,
                leadStage: 0,
            })
        } else {
            value = parseInt(e.target.value.split('|')[0])
            setFilter({
                ...filter,
                pipelineId: parseInt(e.target.value.split('|')[1]),
                leadStage: parseInt(e.target.value.split('|')[0]),
            })
        }
    }

    const requestStatusesList = async () => {
        let response = await useCases?.callUseCase.GetStatusesList()

        if (response instanceof Error) {

        } else {
            setAllStatuses(response!)
            let list: Map<number, string> = new Map()
            let statusesMap: Map<string, string> = new Map()
            let onlyStatusesMap: Map<number, string> = new Map()
            let pipelinesList: { name: string, id: number }[] = [{ name: "Выберите воронку", id: 0 }]
            let statusesList: {name: string, id: number, pipelineId: number, pipelineName: string}[] = [{ name: "Выберите статус", id: 0, pipelineId: 0, pipelineName: "" }]

            response!.forEach((status) => {
                if (!list.get(status.pipelineId)) {
                    list.set(status.pipelineId, status.pipelineName)
                    pipelinesList.push({ name: status.pipelineName, id: status.pipelineId })
                }
                statusesList.push({name: status.statusName, id: status.statusId, pipelineId: status.pipelineId, pipelineName: status.pipelineName})
                statusesMap.set(status.pipelineId.toString() +"_"+status.statusId.toString(), status.statusName + " [" + status.pipelineName + "]")
                onlyStatusesMap.set(status.statusId, status.statusName + " [" + status.pipelineName + "]")
            })

            setStatusesNamesMap(statusesMap)
            setOnlyStatusesMap(onlyStatusesMap)
            setPipelinesList([...pipelinesList])
            setStatusesList([...statusesList])
        }
    }

    useEffect(() => {
        requestStatusesList()
        getOperatorsList()
        getTaskNameOptions()
    }, [])

    const getOperatorsList = async () => {
        let operatos = await useCases?.callUseCase.GetOperatorsList()
        if (operatos instanceof Error) {

        } else {
            let operatorsList: string[] = []
            operatos?.forEach((operator) => {
                if (operator != "") {
                    operatorsList.push(operator)
                }
            })

            setOperatorOptions([...operatorsList])
        }
    }

    const getTaskNameOptions = async () => {
        let tasks = await useCases?.callUseCase.GetTasksList()
        if (tasks instanceof Error) {

        } else {
            let tasksList: string[] = []
            tasks?.forEach((task) => {
                if (task != "") {
                    tasksList.push(task)
                }
            })

            setTaskNameOptions([...tasksList])
        }
    }

    const downloadFile = async () => {
        if (filter) {
            let file = await useCases?.callUseCase.DownloadXlsx(filter.from, filter.to, filter.entityId, filter.taskName, filter.callType, filter.operatorName, filter.pipelineId.toString(), filter.leadStage.toString())

            if (file instanceof Error) {

            } else {

                var link = document.createElement('a');
                link.href = "/api/files/tmp.xlsx";
                link.target = "_blank"
                link.download = "export.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список звонков /</span> Звонки</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"*"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" onClick={(e) => { e.preventDefault(); downloadFile() }}><span>Скачать</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-4" style={{ zIndex: 800 }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="form-label">Дата звонка от:</label>
                                        <input type="date" value={filter?.from ? formatDateForInput(filter?.from!) : undefined} name="from" onChange={dateChange} className="form-control dt-input dt-full-name" />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">Дата звонка до:</label>
                                        <input type="date" value={filter?.to ? formatDateForInput(filter?.to!) : undefined} name="to" onChange={dateChange} className="form-control dt-input dt-full-name" />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">Номер сделки:</label>
                                        <input type="number" value={filter?.entityId} name="entityId" onChange={inputChange} className="form-control dt-input dt-full-name" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Название задачи:</label>
                                        {taskNameOptions.length > 0 ? <Multiselect
                                            isObject={false}
                                            options={taskNameOptions}
                                            selectedValues={filter.taskName}
                                            placeholder=""
                                            onSelect={(selectedList) => {
                                                setFilter({
                                                    ...filter,
                                                    taskName: selectedList,
                                                })
                                            }}

                                            onRemove={(selectedList) => {
                                                setFilter({
                                                    ...filter,
                                                    taskName: selectedList,
                                                })
                                            }}
                                            displayValue="taskName"
                                        /> : <></>}
                                    </div>

                                    <div className="col-md-6">
                                        <label className="form-label">Оператор:</label>
                                        {operatorOptions.length > 0 ? <Multiselect
                                            isObject={false}
                                            placeholder=""
                                            options={operatorOptions}
                                            selectedValues={filter.operatorName}

                                            onSelect={(selectedList) => {
                                                setFilter({
                                                    ...filter,
                                                    operatorName: selectedList,
                                                })
                                            }}

                                            onRemove={(selectedList) => {
                                                setFilter({
                                                    ...filter,
                                                    operatorName: selectedList,
                                                })
                                            }}
                                            displayValue="operatorName"
                                        /> : <></>}

                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label" htmlFor="multicol-email">Тип звонка</label>
                                        <div className="position-relative">
                                            <select id="multicol-country" className="select2 form-select select2-hidden-accessible" tabIndex={-1} name="callType" onChange={inputChange} value={filter?.callType}>
                                                <option selected value={""}>Выберите тип звонка</option>
                                                <option selected value={"входящий"}>Входящий</option>
                                                <option selected value={"исходящий"}>Исходящий</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-password-toggle">
                                            <label className="form-label" htmlFor="multicol-password">Результат звонка</label>
                                            <div className="input-group input-group-merge">
                                                <select id="multicol-country" className="select2 form-select select2-hidden-accessible" onChange={inputChangeStatus} value={filter?.leadStage+"|"+filter.pipelineId} name="leadStage" placeholder="Выберите статус">
                                                    {statusesList.map((amoStatus) => {
                                                        return <option value={amoStatus.id +"|"+amoStatus.pipelineId} data-select2-id="2">{amoStatus.name} [{amoStatus.pipelineName}]</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <Paginator allCount={calls?.count!} page={page} setPage={(page: number) => setPage(page)} countOnPage={countOnPage} setCountOnPage={(count: number) => setCountOnPage(count)} />
                        </div>

                        <div className="table_scroll_main">
                            <div className="table_scroll table">
                                <div className="css_table">
                                    <div className="css_thead">
                                        <div className="css_tr">
                                            <div className="css_th">Дата<br />звонка</div>
                                            <div className="css_th">Номер<br />сделки</div>
                                            <div className="css_th">Название<br />задачи</div>
                                            <div className="css_th">Комментарий</div>
                                            <div className="css_th">Запись<br />звонка</div>
                                            <div className="css_th">Длит-сть<br />звонка</div>
                                            <div className="css_th">Результат<br /> звонка</div>
                                            <div className="css_th">Имя<br />оператора</div>
                                            <div className="css_th">Тип<br />звонка</div>
                                            <div className="css_th">Регион</div>
                                            <div className="css_th">Октелл Id</div>
                                        </div>
                                    </div>
                                    <div className="css_tbody">
                                        {
                                            calls?.calls && calls.calls.map((call, index) => {
                                                return <TableRow call={call} statusName={statusesNamesMap.get(call.PipelineId().toString() +"_"+call.LeadStage().toString()) ? statusesNamesMap.get(call.PipelineId().toString() +"_"+call.LeadStage().toString())! : call.LeadStage() == 142 ? "Целевой статус**" : onlyStatusesMap.get(call.LeadStage())!} last={calls?.calls!.length - 1 == index ? true : false} />
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "end", alignItems: "center", marginTop: "20px" }}>
                            <Paginator allCount={calls?.count!} page={page} setPage={(page: number) => setPage(page)} countOnPage={countOnPage} setCountOnPage={(count: number) => setCountOnPage(count)} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}