import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../../context/useCases";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { FiledType, Form } from "../../../../components/form";
import { Menu } from "../../../../components/menu";

export const AtcAdd = () => {
    const navigate = useNavigate();
    
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        name: string,
        domain: string,
        login: string,
        pass: string,
    }>({
        name: "",
        domain: "",
        login: "",
        pass: "",
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (form.name !== "" && form.domain != "" && form.login != "" && form.pass != "") {
            console.log(loginPassAndDomainToBaseUrl())
            if (id) {
                useCases?.atcUseCase.UpdateAtc(id!, form.name!, loginPassAndDomainToBaseUrl(), () => { navigate(-1) })
            } else {
                useCases?.atcUseCase.CreateAtc(form.name!, loginPassAndDomainToBaseUrl(), () => { navigate(-1) })
            }
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readAtcById = async (id: UniqueId) => {
        let response = await useCases?.atcUseCase.ReadAtcById(id)
        if (response instanceof Error) {

        } else {
            let split = extractLoginPassAndDomainFromBaseUrl(response?.Url()!)
            setForm({
                name: response?.Name()!,
                domain: split.domain,
                login: split.login,
                pass: split.pass,
            })
        }
    }

    const extractLoginPassAndDomainFromBaseUrl = (baseUrl: string) => {
        let loginAndPass = baseUrl.split("@")[0]
        let login = loginAndPass.split(":")[0]
        let pass = loginAndPass.split(":")[1]
        let domain = baseUrl.split("@")[1]
        
        return {domain, login, pass}
    }

    const loginPassAndDomainToBaseUrl = () => {
        return form.login+ ":"+ form.pass + "@"+ form.domain
    }

    useEffect(() => {
        if (id) {
            readAtcById(id)
        }
    }, [id])

    const atcs = useTypedSelector(({ atc }) => {
        return atc
    })

    useEffect(() => {
        if (id) {
            readAtcById(id)
        }
    }, [id])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">АТС /</span> {id ? "Редактировать ATC" : "Добавить ATC"}</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">{id ? "Редактирование ATC" : "Добавление ATC"}</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "name",
                                            title: "Название АТС",
                                            placeholder: "Укажите название АТС",
                                            value: form.name,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "domain",
                                            title: "Базовый домен (пример tel2-leadgen.ru)",
                                            placeholder: "Укажите базовый домен АТС",
                                            value: form.domain,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "login",
                                            title: "Укажите логин",
                                            placeholder: "Укажите логи для АТС",
                                            value: form.login,
                                            type: FiledType.Text,
                                        },
                                        {
                                            name: "pass",
                                            title: "Укажите пароль",
                                            placeholder: "Укажите пароль для АТС",
                                            value: form.pass,
                                            type: FiledType.Text,
                                        },
                                    ]}

                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
