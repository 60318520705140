import { Direction } from "../../domain/occupancy_service/direction/direction";
import { DirectionActionTypes } from "../action-types";
import { DirectionActions } from "../actions";
import produce from 'immer';


interface DirectionState {
    loading: boolean | null;
    directions: Direction[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: DirectionState = {
    loading: null,
    directions: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: DirectionState = initialState, action: DirectionActions) => {
    switch (action.type) {
        case DirectionActionTypes.DIRECTION_REQUEST_SEND:
            state.loading = true;
            return state;
        case DirectionActionTypes.DIRECTION_SUCCESS:
            state.loading = false;
            state.directions = action.payload;
            return state;
        case DirectionActionTypes.DIRECTION_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case DirectionActionTypes.DIRECTION_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case DirectionActionTypes.DIRECTION_CREATE_SUCCESS:
            state.entityLoading = false
            state.directions!.push(action.payload)
            state.entityError = ""
            return state;
        case DirectionActionTypes.DIRECTION_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case DirectionActionTypes.DIRECTION_DELETE_SUCCESS:
            state.directions = state.directions!.filter((directions) => directions.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
