export class Update {
     
    private id: string; 
    private date: Date; 
    private taskSettingsId: string; 
    private oldValue: number; 
    private newValue: number; 
    private result: string; 
    private resultMessage: string; 
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, date: Date, taskSettingsId: string, oldValue: number, newValue: number, result: string, resultMessage: string, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.date = date;
        this.taskSettingsId = taskSettingsId;
        this.oldValue = oldValue;
        this.newValue = newValue;
        this.result = result;
        this.resultMessage = resultMessage;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public Date = (): Date => {
        return this.date
    }
    public TaskSettingsId = (): string => {
        return this.taskSettingsId
    }
    public OldValue = (): number => {
        return this.oldValue
    }
    public NewValue = (): number => {
        return this.newValue
    }
    public Result = (): string => {
        return this.result
    }
    public ResultMessage = (): string => {
        return this.resultMessage
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}