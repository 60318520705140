import { useRef } from "react";
import { TaskReport } from "../../../../domain/occupancy_service/taskreport/taskreport";
import { UpdatesList } from "./updatesList";

export const TableRowGroup = (props: { direction: string, reports: TaskReport[] }) => {
    return (
        <>
            <tr className="css_tr">
                <td rowSpan={9} className="css_sd css_sd_nowrap" style={{display:"flex", justifyContent: "center"}}>{props.direction}</td>
            </tr>
            {props.reports.map((report)=> <TableRow report={report} />)}
        </>
    )
}
export const TableRow = (props: { report: TaskReport }) => {
    const rowRef = useRef<any>(null);
    
    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();
    
        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;
    
        return dd + '.' + mm + '.' + yyyy.toString();
    }

    return (
        <tr key={"lead_" + props.report.Id()} className="css_tr" ref={rowRef} onMouseLeave={() => { console.log("leave"); }}>
            <td className="css_sd css_sd_nowrap">{formatDate(props.report?.Date())}</td>
            <td className="css_sd css_sd_nowrap">{props.report?.AtcName()}</td>
            <td className="css_sd css_sd_nowrap">{props.report?.TaskName()}</td>
            <td className="css_sd css_sd_nowrap">{props.report?.OperatorsCount()}</td>
            <td className="css_sd css_sd_nowrap" style={{backgroundColor: Math.round(props.report?.Occupancy()) != props.report?.EtalonOccupancy()? "#fadadd":""}}>{Math.round(props.report?.Occupancy())}</td>
            <td className="css_sd css_sd_nowrap">{props.report?.EtalonOccupancy()}</td>
            <td className="css_sd css_sd_nowrap">{props.report?.OperatorCallCount()}</td>
            <td className="css_sd css_sd_nowrap">{props.report.RecommendedCallsCountByOccupancy()}</td>
            <td className="css_sd css_sd_nowrap">{<UpdatesList list={props.report.Updates()} />}</td>
        </tr>
    )
}