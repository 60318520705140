import { Dispatch } from "react"
import { TaskSettingsActionTypes } from "../action-types";
import { TaskSettingsActions } from "../actions"
import { TaskSettings } from "../../domain/occupancy_service/tasksettings/tasksettings";

export const SendTaskSettingsListRequest = () => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_REQUEST_SEND,
          });
    }
}

export const TaskSettingsListSuccess = (list: TaskSettings[]) => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_SUCCESS,
            payload: list,
        });
    }
}

export const TaskSettingsListError = (error:string) => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_ERROR,
            payload: error,
        });
    }
}

export const TaskSettingsCreateRequest = () => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_CREATE_REQUEST,
          });
    }
}

export const TaskSettingsCreateSuccess = (tasksettings: TaskSettings) => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_CREATE_SUCCESS,
            payload: tasksettings,
          });
    }
}

export const TaskSettingsCreateError = (message: string) => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_CREATE_ERROR,
            payload: message,
          });
    }
}


export const TaskSettingsUpdateRequest = () => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_UPDATE_REQUEST,
          });
    }
}

export const TaskSettingsUpdateSuccess = (category: TaskSettings) => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const TaskSettingsUpdateError = (message: string) => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const TaskSettingsDeleteRequest = () => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_DELETE_REQUEST,
          });
    }
}

export const TaskSettingsDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const TaskSettingsDeleteError = (message: string) => {
    return async (dispatch: Dispatch<TaskSettingsActions>) => {
        dispatch({
            type: TaskSettingsActionTypes.TASKSETTINGS_DELETE_ERROR,
            payload: message,
          });
    }
}