export class Settings {
     
    private occupancyCheckPeriodInMinutes: number; 
    private modifiedAt: Date;

    constructor( occupancyCheckPeriodInMinutes: number, modifiedAt: Date,) {
        this.occupancyCheckPeriodInMinutes = occupancyCheckPeriodInMinutes;
        this.modifiedAt = modifiedAt;
        
    }

    
    public OccupancyCheckPeriodInMinutes = (): number => {
        return this.occupancyCheckPeriodInMinutes
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}