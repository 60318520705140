import { Dispatch } from "react"
import { UpdateActionTypes } from "../action-types";
import { UpdateActions } from "../actions"
import { Update } from "../../domain/occupancy_service/update/update";

export const SendUpdateListRequest = () => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_REQUEST_SEND,
          });
    }
}

export const UpdateListSuccess = (list: Update[]) => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_SUCCESS,
            payload: list,
        });
    }
}

export const UpdateListError = (error:string) => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_ERROR,
            payload: error,
        });
    }
}

export const UpdateCreateRequest = () => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_CREATE_REQUEST,
          });
    }
}

export const UpdateCreateSuccess = (update: Update) => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_CREATE_SUCCESS,
            payload: update,
          });
    }
}

export const UpdateCreateError = (message: string) => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_CREATE_ERROR,
            payload: message,
          });
    }
}


export const UpdateUpdateRequest = () => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_UPDATE_REQUEST,
          });
    }
}

export const UpdateUpdateSuccess = (category: Update) => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const UpdateUpdateError = (message: string) => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const UpdateDeleteRequest = () => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_DELETE_REQUEST,
          });
    }
}

export const UpdateDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const UpdateDeleteError = (message: string) => {
    return async (dispatch: Dispatch<UpdateActions>) => {
        dispatch({
            type: UpdateActionTypes.UPDATE_DELETE_ERROR,
            payload: message,
          });
    }
}