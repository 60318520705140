export enum SettingsActionTypes {
    SETTINGS_REQUEST_SEND = 'settings_request_send',
    SETTINGS_ERROR = 'settings_error',
    SETTINGS_SUCCESS = 'settings_success',
    
    SETTINGS_CREATE_REQUEST = 'settings_create_request',
    SETTINGS_CREATE_SUCCESS = 'settings_create_success',
    SETTINGS_CREATE_ERROR = 'settings_create_error',

    SETTINGS_DELETE_REQUEST = 'settings_delete_request',
    SETTINGS_DELETE_SUCCESS = 'settings_delete_success',
    SETTINGS_DELETE_ERROR = 'settings_delete_error',

    SETTINGS_UPDATE_REQUEST = 'settings_update_request',
    SETTINGS_UPDATE_SUCCESS = 'settings_update_success',
    SETTINGS_UPDATE_ERROR = 'settings_update_error',
}