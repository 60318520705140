import { Settings } from "../../domain/occupancy_service/settings/settings";
import { SettingsActionTypes } from "../action-types";
import { SettingsActions } from "../actions";
import produce from 'immer';


interface SettingsState {
    loading: boolean | null;
    settings: Settings | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: SettingsState = {
    loading: null,
    settings: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: SettingsState = initialState, action: SettingsActions) => {
    switch (action.type) {
        case SettingsActionTypes.SETTINGS_REQUEST_SEND:
            state.loading = true;
            return state;
        case SettingsActionTypes.SETTINGS_SUCCESS:
            state.loading = false;
            state.settings = action.payload;
            return state;
        case SettingsActionTypes.SETTINGS_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case SettingsActionTypes.SETTINGS_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case SettingsActionTypes.SETTINGS_CREATE_SUCCESS:
            state.entityLoading = false
            state.settings! = action.payload
            state.entityError = ""
            return state;
        case SettingsActionTypes.SETTINGS_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
