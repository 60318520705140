import { useState } from "react";
import { DomainUpdateItem } from "../../../../repository/api/generated/occupancyApi";

export const UpdatesList = (props: { list: DomainUpdateItem[] }) => {

    const [showAll, setShowAll] = useState(false)

    const formatDate = (day: Date) => {

        let hh = day.getHours().toString();
        let min = day.getMinutes().toString();

        if (hh.length == 1) hh = '0' + hh;
        if (min.length == 1) min = '0' + min;

        return hh + ':' + min;
    }

    return <div className="col">
        <ul className="list-group">
            {props.list && props.list.map((updateItem, index) => {
                if (!showAll && index > 0) {
                    return <></>
                }
                return <li className={updateItem.update_result === "success" ? "list-group-item list-group-item-success" : updateItem.update_result === "error" ? "list-group-item list-group-item-danger" : "list-group-item list-group-item-warning"}>
                    {formatDate(new Date(updateItem.date!))} изменение c {updateItem.old_value} на {updateItem.new_value}
                </li>
            })}
        </ul>
        {props.list && props.list.length > 1 ? <button type="button" onClick={() => {setShowAll(!showAll)}} className="btn btn-xs btn-label-secondary waves-effect">{showAll? "Скрыть":"Показать все"}</button> : <></>}
    </div>
}