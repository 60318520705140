import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../../context/useCases";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import { FiledType, Form } from "../../../../components/form";
import { Menu } from "../../../../components/menu";

export const DirectionAdd = () => {
    const navigate = useNavigate();
    
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        name: string,
    }>({
        name: "",
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (form.name !== "") {
            if (id) {
                useCases?.directionUseCase.UpdateDirection(id!, form.name!, () => { navigate(-1) })
            } else {
                useCases?.directionUseCase.CreateDirection(form.name!, () => { navigate(-1) })
            }
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    const readDirectionById = async (id: UniqueId) => {
        let response = await useCases?.directionUseCase.ReadDirectionById(id)
        if (response instanceof Error) {

        } else {
            setForm({
                name: response?.Name()!,
            })
        }
    }

    useEffect(() => {
        if (id) {
            readDirectionById(id)
        }
    }, [id])

    const directions = useTypedSelector(({ direction }) => {
        return direction
    })

    useEffect(() => {
        if (id) {
            readDirectionById(id)
        }
    }, [id])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Направления /</span> {id ? "Редактировать Направление" : "Добавить Направление"}</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">{id ? "Редактирование Направления" : "Добавление Направления"}</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "name",
                                            title: "Название Направления",
                                            placeholder: "Укажите название Направления",
                                            value: form.name,
                                            type: FiledType.Text,
                                        },
                                    ]}

                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
