import { Fragment, useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../../../context/useCases"
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector"
import { TableRow } from "./tableRow"
import { Menu } from "../../../../../components/menu"
import { Link } from "react-router-dom"
import { Direction } from "../../../../../../domain/occupancy_service/direction/direction"
import { Atc } from "../../../../../../domain/occupancy_service/atc/atc"
import { ChevronDown, ChevronUp } from "tabler-icons-react"

export const TaskSettingsList = () => {
    let useCases = useContext(UseCasesContext)

    const [notConfiguredTaskNames, setNotConfiguredTaskNames] = useState<string[]>([])

    const [mapDirections, setMapDirections] = useState<Map<string, Direction>>()
    const [mapAtcs, setMapAtcs] = useState<Map<string, Atc>>()
    const [notConfiguredCollapsed, setNotConfiguredCollapsed] = useState(true)
    const [sorting, setSorting] = useState<string>("")

    const tasksettings = useTypedSelector(({ tasksettings }) => {
        return tasksettings
    })

    const directions = useTypedSelector(({ direction }) => {
        return direction
    })

    const atcs = useTypedSelector(({ atc }) => {
        return atc
    })

    useEffect(() => {
        useCases?.tasksettingsUseCase.ReadTaskSettingss(sorting)
    }, [sorting])

    useEffect(() => {
        useCases?.settingsUseCase.ReadSettings()
    }, [])

    const readNotConfiguredTaskNames = async () => {
        let response = await useCases?.tasksettingsUseCase.ReadNotConfiguredTaskNames()

        if (response instanceof Error) {

        } else {
            setNotConfiguredTaskNames([...response!])
        }
    }

    useEffect(() => {
        readNotConfiguredTaskNames()
    }, [])

    useEffect(() => {
        useCases?.directionUseCase.ReadDirections()
    }, [])

    useEffect(() => {
        useCases?.atcUseCase.ReadAtcs()
    }, [])

    useEffect(() => {
        if (directions) {
            let mapDirection = new Map<string, Direction>();
            directions.directions?.map((direction) => {
                mapDirection.set(direction.Id(), direction)
            })

            setMapDirections(mapDirection)
        }
    }, [directions])

    useEffect(() => {
        if (atcs) {
            let mapAtc = new Map<string, Atc>();
            atcs.atcs?.map((atc) => {
                mapAtc.set(atc.Id(), atc)
            })

            setMapAtcs(mapAtc)
        }
    }, [atcs])

    const setSortingValue = (param: string) => {
        if (getSortingValueForParam(param) === "asc") {
            setSortingValueForParam(param, "desc")
        } else {
            setSortingValueForParam(param, "asc")
        }
    }

    const getSortingValueForParam: (paramName: string) => string = (paramName: string) => {
        let soritngItem = "desc"
        let items = sorting.split(";")

        items.forEach((item) => {
            let param = item.split(':')
            if (param[0] === paramName) {
                if (param[1] == "+") {
                    soritngItem = "asc"
                }
            }
        })

        return soritngItem
    }


    const setSortingValueForParam: (paramName: string, newVal: string) => void = (paramName: string, newVal: string) => {
        let items = sorting.split(";")
        let newItems: string[] = []
        let exists = false

        newItems.push(paramName + ":" + (newVal === "desc" ? "-" : "+"))

        items.forEach((item) => {
            let param = item.split(':')
            if (param[0] !== paramName) {
                newItems.push(item)
            }
        })

        setSorting(newItems.join(';'))
    }


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Настройки по задачам /</span> Настройки по задачам</h4>
                        </div>
                        
                        { notConfiguredTaskNames.length > 0 ? <div className="col-sm-12 col-md-12">
                            <div className="accordion mt-3" id="accordionExample">
                                <div className="card accordion-item">
                                    <h2 className="accordion-header" id="headingOne" onClick={()=>{setNotConfiguredCollapsed(!notConfiguredCollapsed)}}>
                                        <button type="button" className={notConfiguredCollapsed?"accordion-button collapsed":"accordion-button"} data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="false" aria-controls="accordionOne">
                                            Список несконфигурированных задач <span style={{ marginLeft: "5px", color: "red" }}> {notConfiguredTaskNames.length}</span>
                                        </button>
                                    </h2>

                                    <div id="accordionOne" className={notConfiguredCollapsed?"accordion-collapse collapse":"accordion-collapse"} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="list-group">
                                                {notConfiguredTaskNames.map((taskName) => {
                                                    return <Link to={"/task-settings/add/" + taskName} className="list-group-item list-group-item-action">{taskName}</Link>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>: <></>}
                       

                        <div className="col-sm-12">
                            <h5 className="card-header mt-5 mb-5">Настройки по задачам</h5>
                            <div className="table_scroll_main">
                                <div className="table_scroll table">
                                    <div className="css_table">
                                        <div className="css_thead">
                                            <div className="css_tr">
                                                <TableHeaderWithSortingNew title={"АТС"} param="atc_id" active={getSortingValueForParam("atc_id") === "asc"} setSortingValue={setSortingValue} />
                                                <TableHeaderWithSortingNew title={"Направление"} param="direction_id" active={getSortingValueForParam("direction_id") === "asc"} setSortingValue={setSortingValue} />
                                                <TableHeaderWithSortingNew title={"Название"} param="name" active={getSortingValueForParam("name") === "asc"} setSortingValue={setSortingValue} />
                                                <TableHeaderWithSortingNew title={"Кол-во наборов на оператора (текущее)"} param="operators_call_count" active={getSortingValueForParam("operators_call_count") === "asc"} setSortingValue={setSortingValue} />
                                                <div className="css_th" style={{width:"10%"}}>Действия</div>
                                            </div>
                                        </div>
                                        <div className="css_tbody">
                                            {
                                                mapDirections && atcs && tasksettings?.tasksettings && tasksettings?.tasksettings.map((ts, index) => {
                                                    return <TableRow taskSettings={ts} direction={mapDirections!.get(ts.DirectionId())} atcs={mapAtcs?.get(ts.AtcId())} last={tasksettings.tasksettings!.length - 1 == index ? true : false} />
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}


const TableHeaderWithSorting = ({ title, param, setSortingValue, active }: { title: string, param: string, setSortingValue: (input: string) => void, active: boolean }) => {
    return <th style={{ cursor: "pointer", display: "flex", alignItems: "center", width: "100%" }} onClick={() => { setSortingValue(param) }}>{title.indexOf("<br />") !== -1 ? <Fragment>{title.split("<br />")[0]}<br /> {title.split("<br />")[1]}</Fragment> : title} <div style={{ display: "flex", flexDirection: "column", marginLeft: 5 }}><ChevronUp size={15} color={active ? "black" : "grey"} /> <ChevronDown size={15} color={!active ? "black" : "grey"} /></div></th>
}

const TableHeaderWithSortingNew = ({ title, param, setSortingValue, active }: { title: string, param: string, setSortingValue: (input: string) => void, active: boolean }) => {
    return <div className="css_th">
        <div className="css_th_str">
            {title.indexOf("<br />") !== -1 ? <Fragment>{title.split("<br />")[0]}<br />{title.split("<br />")[1]}</Fragment> : title}
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }} onClick={() => { setSortingValue(param) }}>
                <ChevronUp size={15} color={active ? "black" : "grey"} /> <ChevronDown size={15} color={!active ? "black" : "grey"} />
            </div>
        </div>
    </div>


}
