import { DirectionsRepository } from "../../../repository/api/occupancy_service/direction/direction";
import { actionCreators } from "../../../state";

export class DirectionUseCases {
    private api: DirectionsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: DirectionsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadDirections = async () => {
        this.stateActions.SendDirectionListRequest()
        let response = await this.api.ReadDirections()
        if (response instanceof Error) {
            this.stateActions.DirectionListError(response.message)
            return
        } else {
            this.stateActions.DirectionListSuccess(response)
        }
    }

    public DeleteDirection = async (id: UniqueId) => {
        this.stateActions.DirectionDeleteRequest()
        let response = await this.api.DeleteDirectionById(id)
        if (response instanceof Error) {
            this.stateActions.DirectionDeleteError(response.message)
            return
        } else {
            this.stateActions.DirectionDeleteSuccess(id)
        }
    }

    public ReadDirectionById = async (id: UniqueId) => {
        let response = await this.api.ReadDirectionById(id)
        return response
    }

    public CreateDirection = async (name: string, callback?: ()=>void) => {
        this.stateActions.DirectionCreateRequest()
        let response = await this.api.CreateDirection(name,)
        if (response instanceof Error) {
            this.stateActions.DirectionCreateError(response.message)
            return
        } else {
            this.stateActions.DirectionCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateDirection = async (id: UniqueId, name: string, callback?: ()=>void) => {
        this.stateActions.DirectionUpdateRequest()
        let response = await this.api.UpdateDirection(id, name,)
        if (response instanceof Error) {
            this.stateActions.DirectionUpdateError(response.message)
            return
        } else {
            this.stateActions.DirectionUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}