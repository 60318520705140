import { useRef, useState } from "react";
import { Eye, Minus, Plus } from 'tabler-icons-react'
import { useParams } from "react-router-dom";
import { ReportReportGroup } from "../../../../repository/api/generated/timeApi";
import { DetalizationTable } from "./tableDetalization";

export const TableRow = (props: { report: ReportReportGroup, oneDayReport: boolean, last: boolean }) => {
    const { id } = useParams()
    const [showDetalization, setShowDetalization] = useState(false)
    const [showCommunicationId, setShowCommunicationId] = useState(false)
    const rowRef = useRef<any>(null);
    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();
        let hh = day.getHours().toString();
        let mins = day.getMinutes().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;
        if (hh.length == 1) hh = '0' + hh;
        if (mins.length == 1) mins = '0' + mins;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2) + " " + hh + ":" + mins;
    }



    return (
        <>
            <tr onClick={() => { setShowDetalization(!showDetalization) }} style={{ cursor: "pointer", backgroundColor: showDetalization?"#F0FFFF":"" }}>
                <td>{!showDetalization ? <span><Plus size={10} /></span> : <span><Minus size={10} /></span>}</td>
                <td>{props.report.leader_name}</td>
                <td><b>{props.report.post!.toFixed()}</b></td>
                <td><b>{props.report.utilization!.toFixed()}</b></td>
            </tr>

            {showDetalization ? <tr >
                <td colSpan={4} style={{padding: 0, paddingLeft: "15px"}} >
                    <DetalizationTable list={props.report.detalization!} oneDayReport={props.oneDayReport} />
                </td>
            </tr> : <></>}
        </>
    )
}