import { TaskSettingsRepository } from "../../../repository/api/occupancy_service/tasksettings/tasksettings";
import { actionCreators } from "../../../state";

export class TaskSettingsUseCases {
    private api: TaskSettingsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: TaskSettingsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadTaskSettingss = async (sort: string) => {
        this.stateActions.SendTaskSettingsListRequest()
        let response = await this.api.ReadTaskSettings(sort)
        if (response instanceof Error) {
            this.stateActions.TaskSettingsListError(response.message)
            return
        } else {
            this.stateActions.TaskSettingsListSuccess(response)
        }
    }

    public DeleteTaskSettings = async (id: UniqueId) => {
        this.stateActions.TaskSettingsDeleteRequest()
        let response = await this.api.DeleteTaskSettingsById(id)
        if (response instanceof Error) {
            this.stateActions.TaskSettingsDeleteError(response.message)
            return
        } else {
            this.stateActions.TaskSettingsDeleteSuccess(id)
        }
    }

    public ReadTaskSettingsById = async (id: UniqueId) => {
        let response = await this.api.ReadTaskSettingsById(id)
        return response
    }

    public ReadNotConfiguredTaskNames = async () => {
        let response = await this.api.ReadNotConfiguredTaskNames()
        return response
    }

    public CreateTaskSettings = async (name: string,directionId: string,atcId: string,initialOperatorsCallCount: number, recommendedCallsCountByOccupancy:  { [key: string]: number; }, etalonPercentage: number, callback?: ()=>void) => {
        this.stateActions.TaskSettingsCreateRequest()
        let response = await this.api.CreateTaskSettings(name,directionId,atcId,initialOperatorsCallCount, recommendedCallsCountByOccupancy,etalonPercentage,)
        if (response instanceof Error) {
            this.stateActions.TaskSettingsCreateError(response.message)
            return
        } else {
            this.stateActions.TaskSettingsCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateTaskSettings = async (id: UniqueId, name: string,directionId: string,atcId: string,initialOperatorsCallCount: number,  recommendedCallsCountByOccupancy:  { [key: string]: number; }, etalonPercentage: number, callback?: ()=>void) => {
        this.stateActions.TaskSettingsUpdateRequest()
        let response = await this.api.UpdateTaskSettings(id, name,directionId,atcId,initialOperatorsCallCount, recommendedCallsCountByOccupancy,etalonPercentage,)
        if (response instanceof Error) {
            this.stateActions.TaskSettingsUpdateError(response.message)
            return
        } else {
            this.stateActions.TaskSettingsUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}