import axios from "axios";

import { ReportApi, Configuration, ReportReportGroup } from "../generated/timeApi";
import { TimeInterface } from "../../../useCase/time/adapters/interface";

export class TimeReportRepository implements TimeInterface {
    private service: ReportApi;

    constructor() {
        this.service = new ReportApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadTimeReport(from: Date, to: Date): Promise<ReportReportGroup[] | Error> {
        try {
            let response = await this.service.apiTimeReportReportFromToGet(formatDate(from), formatDate(to), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return response.data!
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadTimeReportXlsx(from: Date, to: Date): Promise<string | Error> {
        try {
            let response = await this.service.apiTimeReportReportFromToXlsxGet(formatDate(from), formatDate(to), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return "ok"
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    
}

const formatDate = (day: Date) => {
    if (day.getFullYear() === 1) {
        return ""
    }
    const yyyy = day.getFullYear();
    let mm = (day.getMonth() + 1).toString(); // Months start at 0!
    let dd = day.getDate().toString();

    if (dd.length == 1) dd = '0' + dd;
    if (mm.length == 1) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy.toString();
}