export class TaskSettings {
     
    private id: string; 
    private name: string; 
    private directionId: string; 
    private atcId: string; 
    private initialOperatorsCallCount: number; 
    private recommendedCallsCountByOccupancy: { [key: string]: number; };
    private etalonPercentage: number; 
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, name: string, directionId: string, atcId: string, initialOperatorsCallCount: number, recommendedCallsCountByOccupancy: { [key: string]: number; }, etalonPercentage: number, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.name = name;
        this.directionId = directionId;
        this.atcId = atcId;
        this.initialOperatorsCallCount = initialOperatorsCallCount;
        this.recommendedCallsCountByOccupancy = recommendedCallsCountByOccupancy;
        this.etalonPercentage = etalonPercentage;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
    public DirectionId = (): string => {
        return this.directionId
    }
    public AtcId = (): string => {
        return this.atcId
    }
    public InitialOperatorsCallCount = (): number => {
        return this.initialOperatorsCallCount
    }

    public RecommendedCallsCountByOccupancy = (): { [key: string]: number; } => {
        return this.recommendedCallsCountByOccupancy
    }

    public EtalonPercentage = (): number => {
        return this.etalonPercentage
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}