export enum DirectionActionTypes {
    DIRECTION_REQUEST_SEND = 'direction_request_send',
    DIRECTION_ERROR = 'direction_error',
    DIRECTION_SUCCESS = 'direction_success',
    
    DIRECTION_CREATE_REQUEST = 'direction_create_request',
    DIRECTION_CREATE_SUCCESS = 'direction_create_success',
    DIRECTION_CREATE_ERROR = 'direction_create_error',

    DIRECTION_DELETE_REQUEST = 'direction_delete_request',
    DIRECTION_DELETE_SUCCESS = 'direction_delete_success',
    DIRECTION_DELETE_ERROR = 'direction_delete_error',

    DIRECTION_UPDATE_REQUEST = 'direction_update_request',
    DIRECTION_UPDATE_SUCCESS = 'direction_update_success',
    DIRECTION_UPDATE_ERROR = 'direction_update_error',
}