import { useRef, useState } from "react";
import { Eye } from 'tabler-icons-react'
import { useParams } from "react-router-dom";
import { Call } from "../../../../domain/call/call";
import { AudioLink } from "./audio-link";

export const TableRow = (props: { call: Call, statusName: string, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    const [showCommunicationId, setShowCommunicationId] = useState(false)
    const rowRef = useRef<any>(null);
    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();
        let hh = day.getHours().toString();
        let mins = day.getMinutes().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;
        if (hh.length == 1) hh = '0' + hh;
        if (mins.length == 1) mins = '0' + mins;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2) + " " + hh + ":" + mins;
    }


    const getCallType = () => {
        if (props.call.CallType() == "outgoing") {
            return "исходящий"
        }

        return "входящий"
    }



    return (
        <div key={"lead_" + props.call.Id()} className="css_tr" ref={rowRef} onMouseLeave={() => { console.log("leave"); setShowMenu(false) }}>
            <div className="css_sd css_sd_nowrap">{formatDate(props.call.CallDate())}</div>
            <div className="css_sd css_sd_nowrap"><a href={"https://novostroykigoroda.amocrm.ru/leads/detail/" + props.call.EntityId()} target="_blank">{props.call.EntityId()}</a></div>
            <div className="css_sd css_sd_nowrap">{props.call.TaskName()}</div>
            <div className="css_sd css_sd_top" style={{ minWidth: "400px" }}>{props.call.Comment()}</div>
            <div className="css_sd css_sd_nowrap css_sd_centre">{props.call.CallLength() > 0 ? <AudioLink url={props.call.CallLink()} leadId={props.call.EntityId()} /> : <></>}</div>
            <div className="css_sd css_sd_nowrap">{props.call.CallLength()}</div>
            <div className="css_sd css_sd_nowrap">{props.statusName}</div>
            <div className="css_sd css_sd_nowrap">{props.call.OperatorName()}</div>
            <div className="css_sd css_sd_nowrap">{getCallType()}</div>
            <div className="css_sd css_sd_nowrap">{props.call.Region()}</div>
            <div className="css_sd css_sd_nowrap"> {!showCommunicationId ? <button type="button" onClick={() => { setShowCommunicationId(!showCommunicationId) }} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                <Eye />
            </button> : props.call.CommunicationId()}</div>
        </div>
    )
}