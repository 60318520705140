import { useContext, useEffect, useState } from "react"
import { TableRow, TableRowGroup } from "./tableRow"
import { UseCasesContext } from "../../../../context/useCases"
import { Menu } from "../../../components/menu"
import { TaskReport } from "../../../../domain/occupancy_service/taskreport/taskreport"

export const OccupancyReport = () => {

    const getYesterday = () => {
        var date = new Date();
        date.setDate(date.getDate() - 1);

        return date;
    }
    const [filter, setFilter] = useState<{ from: Date, to: Date }>({
        from: getYesterday(),
        to: new Date(),
    })

    const [report, setReport] = useState<{[key: string]: TaskReport[]}>({})
    const [live, setLive] = useState(true)

    let useCases = useContext(UseCasesContext)

    const readRangeReport = async () => {
        setReport({})
        let response = await useCases?.reportUseCase.ReadReport(filter.from, filter.to)
        if (response instanceof Error) {

        } else {
            setReport(response!)
        }
    }

    const readLiveReport = async () => {
        let response = await useCases?.reportUseCase.ReadLiveReport()
        if (response instanceof Error) {

        } else {
            setReport(response!)
        }
    }

    useEffect(() => {
        if (live) {
            readLiveReport()
        } else {
            readRangeReport()
        }
    }, [live, filter])


    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (filter) {
            setFilter({
                ...filter,
                [e.target.name]: new Date(e.target.value)
            })
        }
    }

    const formatDateForInput = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    const downloadFile = async () => {
        if (filter) {
            let file = await useCases?.reportUseCase.DownloadReport(filter.from, filter.to)

            if (file instanceof Error) {

            } else {

                var link = document.createElement('a');
                link.href = "/api/occupancy/files/tmp.xlsx";
                link.target = "_blank"
                link.download = "export.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Отчет по занятости /</span> Занятость по задачам</h4>
                        </div>

                        <div className="card mb-4" style={{ zIndex: 800 }}>
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" checked={live} autoComplete="off" />
                                            <label className="btn btn-outline-primary waves-effect" onClick={()=>{setLive(true)}} htmlFor="btnradio1">Live</label>

                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" checked={!live} autoComplete="off" />
                                            <label className="btn btn-outline-primary waves-effect" onClick={()=>{setLive(false)}} htmlFor="btnradio2">Архив</label>
                                        </div>
                                        
                                    </div>
                                   
                                </div>

                                {!live?<div className="row mt-4" >
                                    <div className="col-md-4">
                                        <label className="form-label">Дата звонка от:</label>
                                        <input type="date" value={filter?.from ? formatDateForInput(filter?.from!) : undefined} name="from" onChange={dateChange} className="form-control dt-input dt-full-name" />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">Дата звонка до:</label>
                                        <input type="date" value={filter?.to ? formatDateForInput(filter?.to!) : undefined} name="to" onChange={dateChange} className="form-control dt-input dt-full-name" />
                                    </div>
                                    <div className="col-md-4" style={{display: "flex", alignItems: "end"}}>
                                        <a href="#" className="dt-button add-new btn btn-primary mb-3 mb-md-0" onClick={(e) => { e.preventDefault(); downloadFile() }}><span>Скачать</span></a>
                                    </div>
                                </div>:<></>}
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <h5 className="card-header mt-5 mb-5">Занятость по задачам</h5>
                            <div className="table_scroll_main">
                                <div className="table_scroll table">
                                    <table className="css_table">
                                        <th className="css_thead">
                                            <tr className="css_tr">
                                                <td className="css_th">Дата</td>
                                                <td className="css_th">Октелл</td>
                                                <td className="css_th">Название задачи</td>
                                                <td className="css_th">Количество <br /> людей</td>
                                                <td className="css_th">Текущий %<br /> занятости</td>
                                                <td className="css_th">Эталонный %<br /> занятости</td>
                                                <td className="css_th">Кол-во наборов <br /> на оператора</td>
                                                <td className="css_th">Рекомендуемое кол-во <br /> наборов на оператора</td>
                                                <td className="css_th">Статус по <br /> изменениям</td>
                                            </tr>
                                        </th>
                                        <tbody className="css_tbody">
                                            {
                                                report && Object.keys(report).map((direction, index) => {
                                                    return <TableRowGroup  direction={direction} reports={report[direction]} />
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}