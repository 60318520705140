import { Dispatch } from "react"
import { SettingsActionTypes } from "../action-types";
import { SettingsActions } from "../actions"
import { Settings } from "../../domain/occupancy_service/settings/settings";

export const SendSettingsListRequest = () => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_REQUEST_SEND,
          });
    }
}

export const SettingsSuccess = (list: Settings) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_SUCCESS,
            payload: list,
        });
    }
}

export const SettingsListError = (error:string) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_ERROR,
            payload: error,
        });
    }
}

export const SettingsCreateRequest = () => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_CREATE_REQUEST,
          });
    }
}

export const SettingsCreateSuccess = (settings: Settings) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_CREATE_SUCCESS,
            payload: settings,
          });
    }
}

export const SettingsCreateError = (message: string) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_CREATE_ERROR,
            payload: message,
          });
    }
}


export const SettingsUpdateRequest = () => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_UPDATE_REQUEST,
          });
    }
}

export const SettingsUpdateSuccess = (category: Settings) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const SettingsUpdateError = (message: string) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const SettingsDeleteRequest = () => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_DELETE_REQUEST,
          });
    }
}

export const SettingsDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const SettingsDeleteError = (message: string) => {
    return async (dispatch: Dispatch<SettingsActions>) => {
        dispatch({
            type: SettingsActionTypes.SETTINGS_DELETE_ERROR,
            payload: message,
          });
    }
}