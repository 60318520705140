import { Dispatch } from "react"
import { CallActionTypes } from "../action-types";
import { CallActions } from "../actions"
import { Call } from "../../domain/call/call";

export const SendCallListRequest = () => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_REQUEST_SEND,
          });
    }
}

export const CallListSuccess = (count: number, list: Call[]) => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_SUCCESS,
            payload: { count: count, list: list },
        });
    }
}

export const CallListError = (error:string) => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_ERROR,
            payload: error,
        });
    }
}

export const CallCreateRequest = () => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_CREATE_REQUEST,
          });
    }
}

export const CallCreateSuccess = (call: Call) => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_CREATE_SUCCESS,
            payload: call,
          });
    }
}

export const CallCreateError = (message: string) => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_CREATE_ERROR,
            payload: message,
          });
    }
}


export const CallUpdateRequest = () => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_UPDATE_REQUEST,
          });
    }
}

export const CallUpdateSuccess = (category: Call) => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const CallUpdateError = (message: string) => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const CallDeleteRequest = () => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_DELETE_REQUEST,
          });
    }
}

export const CallDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const CallDeleteError = (message: string) => {
    return async (dispatch: Dispatch<CallActions>) => {
        dispatch({
            type: CallActionTypes.CALL_DELETE_ERROR,
            payload: message,
          });
    }
}