export enum AtcActionTypes {
    ATC_REQUEST_SEND = 'atc_request_send',
    ATC_ERROR = 'atc_error',
    ATC_SUCCESS = 'atc_success',
    
    ATC_CREATE_REQUEST = 'atc_create_request',
    ATC_CREATE_SUCCESS = 'atc_create_success',
    ATC_CREATE_ERROR = 'atc_create_error',

    ATC_DELETE_REQUEST = 'atc_delete_request',
    ATC_DELETE_SUCCESS = 'atc_delete_success',
    ATC_DELETE_ERROR = 'atc_delete_error',

    ATC_UPDATE_REQUEST = 'atc_update_request',
    ATC_UPDATE_SUCCESS = 'atc_update_success',
    ATC_UPDATE_ERROR = 'atc_update_error',
}