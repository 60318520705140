import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../../pages/auth/login/login";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useContext, useEffect } from "react";
import { UseCasesContext } from "../../../context/useCases";
import { UserList } from "../../pages/users/list";
import { UserAdd } from "../../pages/users/add";
import { ChangePass } from "../../pages/users/change-pass";
import { CallList } from "../../pages/call/list";
import { TimeReport } from "../../pages/time/list";
import { AtcList } from "../../pages/occupancy_service/atc/list";
import { AtcAdd } from "../../pages/occupancy_service/atc/add";
import { DirectionAdd } from "../../pages/occupancy_service/direction/add";
import { DirectionList } from "../../pages/occupancy_service/direction/list";
import { TaskSettingsList } from "../../pages/occupancy_service/task/settings/list";
import { TaskSettingsEdit } from "../../pages/occupancy_service/task/settings/edit";
import { TaskSettingsAdd } from "../../pages/occupancy_service/task/settings/add/task-settings-add";
import { OccupancySettingsEdit } from "../../pages/occupancy_service/settings/edit";
import { OccupancyReport } from "../../pages/occupancy_service/occupancy-report";


export const RouterComponent = () => {
  let useCases = useContext(UseCasesContext)

  const login = useTypedSelector(({ login }) => {
    return login
  })

  useEffect(() => {
    useCases?.authUseCase.CheckAuthorization()
    setInterval(() => {
      useCases?.authUseCase.CheckAuthorization()
    }, 10000)
  }, [])


  return (<Router basename="/">
    <Routes>
      {login?.authorized ? <>
          <Route path="/occupancy-report" element={<OccupancyReport />} />

          <Route path="/time-report" element={<TimeReport />} />

          <Route path="/occupancy-settings" element={<OccupancySettingsEdit />} />
          <Route path="/task-settings/add/:name" element={<TaskSettingsAdd />} />
          <Route path="/task-settings/edit/:id" element={<TaskSettingsEdit />} />
          <Route path="/task-settings" element={<TaskSettingsList />} />

          <Route path="/user/password/:id" element={<ChangePass />} />
          <Route path="/user/edit/:id" element={<UserAdd />} />
          <Route path="/user/add" element={<UserAdd />} />
          <Route path="/user" element={<UserList />} />

          <Route path="/atc/edit/:id" element={<AtcAdd />} />
          <Route path="/atc/add" element={<AtcAdd />} />
          <Route path="/atc" element={<AtcList />} />

          <Route path="/direction/edit/:id" element={<DirectionAdd />} />
          <Route path="/direction/add" element={<DirectionAdd />} />
          <Route path="/direction" element={<DirectionList />} />

          <Route path="*" element={<CallList />} />
        </> : <>
        <Route path="*" element={<Login />} />
      </>}
    </Routes>
  </Router>
  )
}