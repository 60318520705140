import { SettingsRepository } from "../../../repository/api/occupancy_service/settings/settings";
import { actionCreators } from "../../../state";

export class SettingsUseCases {
    private api: SettingsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: SettingsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadSettings = async () => {
        this.stateActions.SendSettingsListRequest()
        let response = await this.api.ReadSettings()
        if (response instanceof Error) {
            this.stateActions.SettingsListError(response.message)
            return
        } else {
            this.stateActions.SettingsSuccess(response)
        }
    }

    public CreateSettings = async (occupancyCheckPeriodInMinutes: number, callback?: ()=>void) => {
        this.stateActions.SettingsCreateRequest()
        let response = await this.api.CreateSettings(occupancyCheckPeriodInMinutes,)
        if (response instanceof Error) {
            this.stateActions.SettingsCreateError(response.message)
            return
        } else {
            this.stateActions.SettingsCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}