
import { AtcsRepository } from "../../../repository/api/occupancy_service/atc/atc";
import { actionCreators } from "../../../state";

export class AtcUseCases {
    private api: AtcsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: AtcsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadAtcs = async () => {
        this.stateActions.SendAtcListRequest()
        let response = await this.api.ReadAtcs()
        if (response instanceof Error) {
            this.stateActions.AtcListError(response.message)
            return
        } else {
            this.stateActions.AtcListSuccess(response)
        }
    }

    public DeleteAtc = async (id: UniqueId) => {
        this.stateActions.AtcDeleteRequest()
        let response = await this.api.DeleteAtcById(id)
        if (response instanceof Error) {
            this.stateActions.AtcDeleteError(response.message)
            return
        } else {
            this.stateActions.AtcDeleteSuccess(id)
        }
    }

    public ReadAtcById = async (id: UniqueId) => {
        let response = await this.api.ReadAtcById(id)
        return response
    }

    public CreateAtc = async (name: string, url: string, callback?: ()=>void) => {
        this.stateActions.AtcCreateRequest()
        let response = await this.api.CreateAtc(name, url)
        if (response instanceof Error) {
            this.stateActions.AtcCreateError(response.message)
            return
        } else {
            this.stateActions.AtcCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdateAtc = async (id: UniqueId, name: string, url: string, callback?: ()=>void) => {
        this.stateActions.AtcUpdateRequest()
        let response = await this.api.UpdateAtc(id, name, url)
        if (response instanceof Error) {
            this.stateActions.AtcUpdateError(response.message)
            return
        } else {
            this.stateActions.AtcUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

}