
import { Atc } from "../../domain/occupancy_service/atc/atc";
import { AtcActionTypes } from "../action-types";
import { AtcActions } from "../actions";
import produce from 'immer';


interface AtcState {
    loading: boolean | null;
    atcs: Atc[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: AtcState = {
    loading: null,
    atcs: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: AtcState = initialState, action: AtcActions) => {
    switch (action.type) {
        case AtcActionTypes.ATC_REQUEST_SEND:
            state.loading = true;
            return state;
        case AtcActionTypes.ATC_SUCCESS:
            state.loading = false;
            state.atcs = action.payload;
            return state;
        case AtcActionTypes.ATC_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case AtcActionTypes.ATC_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case AtcActionTypes.ATC_CREATE_SUCCESS:
            state.entityLoading = false
            state.atcs!.push(action.payload)
            state.entityError = ""
            return state;
        case AtcActionTypes.ATC_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case AtcActionTypes.ATC_DELETE_SUCCESS:
            state.atcs = state.atcs!.filter((atcs) => atcs.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
