import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import userReducer from './user';
import callReducer from './call';

import atcReducer from './atc'; 
import directionReducer from './direction'; 
import settingsReducer from './settings'; 
import tasksettingsReducer from './tasksettings'; 
import updateReducer from './update';

const reducers = combineReducers({
    login: loginReducer,
    user: userReducer,
    call: callReducer,
    
    atc : atcReducer,
    direction : directionReducer,
    settings : settingsReducer,
    tasksettings : tasksettingsReducer,
    update : updateReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>;