import { Dispatch } from "react"
import { DirectionActionTypes } from "../action-types";
import { DirectionActions } from "../actions"
import { Direction } from "../../domain/occupancy_service/direction/direction";

export const SendDirectionListRequest = () => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_REQUEST_SEND,
          });
    }
}

export const DirectionListSuccess = (list: Direction[]) => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_SUCCESS,
            payload: list,
        });
    }
}

export const DirectionListError = (error:string) => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_ERROR,
            payload: error,
        });
    }
}

export const DirectionCreateRequest = () => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_CREATE_REQUEST,
          });
    }
}

export const DirectionCreateSuccess = (direction: Direction) => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_CREATE_SUCCESS,
            payload: direction,
          });
    }
}

export const DirectionCreateError = (message: string) => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_CREATE_ERROR,
            payload: message,
          });
    }
}


export const DirectionUpdateRequest = () => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_UPDATE_REQUEST,
          });
    }
}

export const DirectionUpdateSuccess = (category: Direction) => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const DirectionUpdateError = (message: string) => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const DirectionDeleteRequest = () => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_DELETE_REQUEST,
          });
    }
}

export const DirectionDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const DirectionDeleteError = (message: string) => {
    return async (dispatch: Dispatch<DirectionActions>) => {
        dispatch({
            type: DirectionActionTypes.DIRECTION_DELETE_ERROR,
            payload: message,
          });
    }
}