import axios from "axios";
import { TaskSettings } from "../../../../domain/occupancy_service/tasksettings/tasksettings";
import { Configuration, TaskSettingssApi, TaskSettingsTaskSettingsResponse, } from "../../generated/occupancyApi";

export class TaskSettingsRepository {
    private service: TaskSettingssApi;

    constructor() {
        this.service = new TaskSettingssApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadTaskSettings(sort: string): Promise<TaskSettings[] | Error> {
        try {
            let response = await this.service.apiOccupancyTaskSettingsGet(sort, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let tasksettingss : TaskSettings[] = []
                response.data?.map((tasksettingsApi) => {
                    let tasksettings = this.toDomainTaskSettings(tasksettingsApi);
                    tasksettingss.push(tasksettings)
                })
                return tasksettingss
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadNotConfiguredTaskNames(): Promise<string[] | Error> {
        try {
            let response = await this.service.apiOccupancyTaskSettingsNotConfiguredGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateTaskSettings(name: string,directionId: string,atcId: string,initialOperatorsCallCount: number, recommendedCallsCountByOccupancy:  { [key: string]: number; }, etalonPercentage: number,): Promise<TaskSettings | Error> {
        try {
            let response = await this.service.apiOccupancyTaskSettingsPost({ name: name,direction_id: directionId,atc_id: atcId,operators_call_count: initialOperatorsCallCount, recommended_calls_count_by_occupancy: recommendedCallsCountByOccupancy, etalon_percentage: etalonPercentage, }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201 || response.status === 200) {
                let tasksettings = this.toDomainTaskSettings(response.data);
                return tasksettings
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateTaskSettings(id: string,name: string,directionId: string,atcId: string,initialOperatorsCallCount: number,  recommendedCallsCountByOccupancy:  { [key: string]: number; }, etalonPercentage: number,): Promise<TaskSettings | Error> {
        try {

            let response = await this.service.apiOccupancyTaskSettingsIdPut(id, { name: name,direction_id: directionId,atc_id: atcId,operators_call_count: initialOperatorsCallCount, recommended_calls_count_by_occupancy: recommendedCallsCountByOccupancy, etalon_percentage: etalonPercentage, }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let tasksettings = this.toDomainTaskSettings(response.data);
                return tasksettings
            }

        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadTaskSettingsById(id: UniqueId): Promise<TaskSettings | Error> {
        try {

            let response = await this.service.apiOccupancyTaskSettingsIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let tasksettings = this.toDomainTaskSettings(response.data);
                return tasksettings
            }

        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeleteTaskSettingsById(id: UniqueId): Promise<string | Error> {
        try {

            let response = await this.service.apiOccupancyTaskSettingsIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return id
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    

    toDomainTaskSettings(tasksettings: TaskSettingsTaskSettingsResponse): TaskSettings {
        return new TaskSettings( tasksettings.id!, tasksettings.name!, tasksettings.direction_id!, tasksettings.atc_id!, tasksettings.operators_call_count!, tasksettings.recommended_calls_count_by_occupancy!, tasksettings.etalon_percentage!, new Date(tasksettings.created_at!), new Date(tasksettings.modified_at!), );
    }
    
}