import { UpdatesRepository } from "../../../repository/api/occupancy_service/update/update";
import { actionCreators } from "../../../state";

export class UpdateUseCases {
    private api: UpdatesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: UpdatesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadUpdates = async () => {
        this.stateActions.SendUpdateListRequest()
        let response = await this.api.ReadUpdates()
        if (response instanceof Error) {
            this.stateActions.UpdateListError(response.message)
            return
        } else {
            this.stateActions.UpdateListSuccess(response)
        }
    }

}