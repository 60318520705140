import { DomainUpdateItem } from "../../../repository/api/generated/occupancyApi";

export class TaskReport {
     
    private id: string; 
    private date: Date; 
    private atcName: string; 
    private taskName: string; 
    private direction: string; 
    private operatorsCount: number; 
    private occupancy: number; 
    private etalonOccupancy: number; 
    private operatorCallCount: number; 
    private recommendedCallsCountByOccupancy: number; 
    private updates: DomainUpdateItem[];

    constructor( id: string, date: Date, atcName: string, taskName: string, direction: string, operatorsCount: number, occupancy: number, etalonOccupancy: number, operatorCallCount: number, recommendedCallsCountByOccupancy: number, updates: DomainUpdateItem[],) {
        this.id = id;
        this.date = date;
        this.atcName = atcName;
        this.taskName = taskName;
        this.direction = direction;
        this.operatorsCount = operatorsCount;
        this.occupancy = occupancy;
        this.etalonOccupancy = etalonOccupancy;
        this.operatorCallCount = operatorCallCount;
        this.recommendedCallsCountByOccupancy = recommendedCallsCountByOccupancy;
        this.updates = updates;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public Date = (): Date => {
        return this.date
    }
    public AtcName = (): string => {
        return this.atcName
    }
    public TaskName = (): string => {
        return this.taskName
    }
    public Direction = (): string => {
        return this.direction
    }
    public OperatorsCount = (): number => {
        return this.operatorsCount
    }
    public Occupancy = (): number => {
        return this.occupancy
    }
    public EtalonOccupancy = (): number => {
        return this.etalonOccupancy
    }
    public OperatorCallCount = (): number => {
        return this.operatorCallCount
    }
    public RecommendedCallsCountByOccupancy = (): number => {
        return this.recommendedCallsCountByOccupancy
    }
    public Updates = (): DomainUpdateItem[] => {
        return this.updates
    }
}