import { Dispatch } from "react"
import { AtcActionTypes } from "../action-types";
import { AtcActions } from "../actions"
import { Atc } from "../../domain/occupancy_service/atc/atc";

export const SendAtcListRequest = () => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_REQUEST_SEND,
          });
    }
}

export const AtcListSuccess = (list: Atc[]) => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_SUCCESS,
            payload: list,
        });
    }
}

export const AtcListError = (error:string) => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_ERROR,
            payload: error,
        });
    }
}

export const AtcCreateRequest = () => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_CREATE_REQUEST,
          });
    }
}

export const AtcCreateSuccess = (atc: Atc) => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_CREATE_SUCCESS,
            payload: atc,
          });
    }
}

export const AtcCreateError = (message: string) => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_CREATE_ERROR,
            payload: message,
          });
    }
}


export const AtcUpdateRequest = () => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_UPDATE_REQUEST,
          });
    }
}

export const AtcUpdateSuccess = (category: Atc) => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const AtcUpdateError = (message: string) => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const AtcDeleteRequest = () => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_DELETE_REQUEST,
          });
    }
}

export const AtcDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const AtcDeleteError = (message: string) => {
    return async (dispatch: Dispatch<AtcActions>) => {
        dispatch({
            type: AtcActionTypes.ATC_DELETE_ERROR,
            payload: message,
          });
    }
}