import { useContext, useEffect } from "react"
import { UseCasesContext } from "../../../../../context/useCases"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"
import { TableRow } from "./tableRow"
import { Menu } from "../../../../components/menu"
import { Link } from "react-router-dom"

export const AtcList = () => {
    let useCases = useContext(UseCasesContext)


    const atcs = useTypedSelector(({ atc }) => {
        return atc
    })

    useEffect(() => {
        useCases?.atcUseCase.ReadAtcs()
    }, [])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список АТС /</span> АТС</h4>
                        </div>

                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/atc/add/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Добавить ATC</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="table_scroll_main">
                            <div className="table_scroll table">
                                <div className="css_table">
                                    <div className="css_thead">
                                        <div className="css_tr">
                                            <div className="css_th">Название</div>
                                            <div className="css_th">Дата создания</div>
                                            <div className="css_th" style={{width:"10%"}}>Действия</div>
                                        </div>
                                    </div>
                                    <div className="css_tbody">
                                        {
                                            atcs?.atcs && atcs?.atcs.map((atc, index) => {
                                                return <TableRow atc={atc} last={ false} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}