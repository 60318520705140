import { createContext } from 'react'
import { AuthorizationUseCases } from '../useCase/authorization/authorization';
import { UserUseCases } from '../useCase/users/user';
import { CallUseCases } from '../useCase/call/call';
import { TimeUseCases } from '../useCase/time/time';
import { AtcUseCases } from '../useCase/occupancy_service/atc/atc';
import { DirectionUseCases } from '../useCase/occupancy_service/direction/direction';
import { SettingsUseCases } from '../useCase/occupancy_service/settings/settings';
import { TaskSettingsUseCases } from '../useCase/occupancy_service/tasksettings/tasksettings';
import { UpdateUseCases } from '../useCase/occupancy_service/update/update';
import { ReportUseCases } from '../useCase/occupancy_service/report/report';
 

interface UseCasesContextInterface {
    authUseCase: AuthorizationUseCases;
    userUseCase: UserUseCases;
    callUseCase: CallUseCases;
    timeUseCase: TimeUseCases;

    atcUseCase: AtcUseCases; 
    directionUseCase: DirectionUseCases; 
    settingsUseCase: SettingsUseCases; 
    tasksettingsUseCase: TaskSettingsUseCases; 
    updateUseCase: UpdateUseCases;
    reportUseCase: ReportUseCases;
}

export const UseCasesContext = createContext<UseCasesContextInterface | null>(null)