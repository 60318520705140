export enum TaskSettingsActionTypes {
    TASKSETTINGS_REQUEST_SEND = 'tasksettings_request_send',
    TASKSETTINGS_ERROR = 'tasksettings_error',
    TASKSETTINGS_SUCCESS = 'tasksettings_success',
    
    TASKSETTINGS_CREATE_REQUEST = 'tasksettings_create_request',
    TASKSETTINGS_CREATE_SUCCESS = 'tasksettings_create_success',
    TASKSETTINGS_CREATE_ERROR = 'tasksettings_create_error',

    TASKSETTINGS_DELETE_REQUEST = 'tasksettings_delete_request',
    TASKSETTINGS_DELETE_SUCCESS = 'tasksettings_delete_success',
    TASKSETTINGS_DELETE_ERROR = 'tasksettings_delete_error',

    TASKSETTINGS_UPDATE_REQUEST = 'tasksettings_update_request',
    TASKSETTINGS_UPDATE_SUCCESS = 'tasksettings_update_success',
    TASKSETTINGS_UPDATE_ERROR = 'tasksettings_update_error',
}