import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../../../context/useCases";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import { FiledType, Form } from "../../../../../components/form";
import { Menu } from "../../../../../components/menu";

export const TaskSettingsEdit = () => {
    const navigate = useNavigate();
    
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)

    const [form, setForm] = useState<{
        name: string,
        directionId: string,
        atcId: string,
        initialOperatorsCallCount: number,
        recommendedCallsCountByOccupancy:  { [key: string]: number; },
        etalonPercentage: number,
    }>({
        name: "",
        directionId: "",
        atcId: "",
        initialOperatorsCallCount: 1,
        recommendedCallsCountByOccupancy:  {"100": 1},
        etalonPercentage: 10,
    })

    const [directionOptions, setDirectionOptions] = useState<{title: string, value: string}[]>([])
    const [atcOptions, setAtcOptions] = useState<{title: string, value: string}[]>([])

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (id) {
            useCases?.tasksettingsUseCase.UpdateTaskSettings(id!, form.name!, form.directionId, form.atcId, form.initialOperatorsCallCount, form.recommendedCallsCountByOccupancy, form.etalonPercentage, () => { navigate(-1) })
        }
    }

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }


    const taskSettings = useTypedSelector(({ tasksettings }) => {
        return tasksettings
    })

    const directions = useTypedSelector(({ direction }) => {
        return direction
    })

    const atcs = useTypedSelector(({ atc }) => {
        return atc
    })

    const readTaskSettingsById = async () => {
        if (id) {
            let response = await useCases?.tasksettingsUseCase.ReadTaskSettingsById(id!)
            if (response instanceof Error) {

            } else {
                setForm({
                    name: response!.Name(),
                    directionId: response!.DirectionId(),
                    atcId: response!.AtcId(),
                    initialOperatorsCallCount: response!.InitialOperatorsCallCount(),
                    recommendedCallsCountByOccupancy: response!.RecommendedCallsCountByOccupancy(),
                    etalonPercentage: response!.EtalonPercentage(),
                })
            }
        }
    }

    useEffect(()=>{
        useCases?.directionUseCase.ReadDirections()
    },[])

    useEffect(()=>{
        useCases?.atcUseCase.ReadAtcs()
    },[])

    useEffect(()=>{
        readTaskSettingsById()
    },[])

    useEffect(()=>{
        if (directions?.directions) {
            let newDirections :{title: string, value: string}[] = []
            directions.directions.map((direction)=>{
                newDirections.push({title: direction.Name(), value: direction.Id()})
            })

            setDirectionOptions(newDirections)
        }
    },[directions])

    useEffect(()=>{
        if (atcs?.atcs) {
            let newAtcs :{title: string, value: string}[] = []
            atcs.atcs.map((direction)=>{
                newAtcs.push({title: direction.Name(), value: direction.Id()})
            })

            setAtcOptions(newAtcs)
        }
    },[atcs])



    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Настройка параметров для задачи /</span>{form.name}</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Настройка параметров задачи</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "directionId",
                                            title: "Направление",
                                            placeholder: "Выберите направление из списка",
                                            value: form.directionId,
                                            type: FiledType.Select,
                                            options: directionOptions,
                                        },
                                        {
                                            name: "atcId",
                                            title: "ATC",
                                            placeholder: "Выберите ATC из списка",
                                            value: form.atcId,
                                            type: FiledType.Select,
                                            options: atcOptions,
                                        },
                                        {
                                            name: "initialOperatorsCallCount",
                                            title: "Кол-во наборов на оператора (текущее)",
                                            placeholder: "Укажите кол-во наборов на оператора",
                                            value: form.initialOperatorsCallCount,
                                            type: FiledType.Number,
                                        },
                                        {
                                            name: "recommendedCallsCountByOccupancy",
                                            title: "Рекомендуемое кол-во наборов в зависимости от % занятости",
                                            placeholder: "Укажите рекомендуемое кол-во наборов в зависимости от % занятости",
                                            value: form.recommendedCallsCountByOccupancy,
                                            type: FiledType.RangeValueMapField,
                                        },
                                        {
                                            name: "etalonPercentage",
                                            title: "Эталонный % занятости",
                                            placeholder: "Укажите эталонный % занятости",
                                            value: form.etalonPercentage,
                                            type: FiledType.Number,
                                        },
                                    ]}

                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}