import { Call } from "../../domain/call/call";
import { CallActionTypes } from "../action-types";
import { CallActions } from "../actions";
import produce from 'immer';


interface CallState {
    loading: boolean | null;
    count: number,
    calls: Call[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: CallState = {
    loading: null,
    count: 0,
    calls: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: CallState = initialState, action: CallActions) => {
    switch (action.type) {
        case CallActionTypes.CALL_REQUEST_SEND:
            state.loading = true;
            return state;
        case CallActionTypes.CALL_SUCCESS:
            state.loading = false;
            state.count = action.payload.count;
            state.calls = action.payload.list;
            return state;
        case CallActionTypes.CALL_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case CallActionTypes.CALL_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case CallActionTypes.CALL_CREATE_SUCCESS:
            state.entityLoading = false
            state.calls!.push(action.payload)
            state.entityError = ""
            return state;
        case CallActionTypes.CALL_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case CallActionTypes.CALL_DELETE_SUCCESS:
            state.calls = state.calls!.filter((calls) => calls.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
