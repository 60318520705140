import { TaskSettings } from "../../domain/occupancy_service/tasksettings/tasksettings";
import { TaskSettingsActionTypes } from "../action-types";
import { TaskSettingsActions } from "../actions";
import produce from 'immer';


interface TaskSettingsState {
    loading: boolean | null;
    tasksettings: TaskSettings[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: TaskSettingsState = {
    loading: null,
    tasksettings: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: TaskSettingsState = initialState, action: TaskSettingsActions) => {
    switch (action.type) {
        case TaskSettingsActionTypes.TASKSETTINGS_REQUEST_SEND:
            state.loading = true;
            return state;
        case TaskSettingsActionTypes.TASKSETTINGS_SUCCESS:
            state.loading = false;
            state.tasksettings = action.payload;
            return state;
        case TaskSettingsActionTypes.TASKSETTINGS_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case TaskSettingsActionTypes.TASKSETTINGS_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case TaskSettingsActionTypes.TASKSETTINGS_CREATE_SUCCESS:
            state.entityLoading = false
            state.tasksettings!.push(action.payload)
            state.entityError = ""
            return state;
        case TaskSettingsActionTypes.TASKSETTINGS_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case TaskSettingsActionTypes.TASKSETTINGS_DELETE_SUCCESS:
            state.tasksettings = state.tasksettings!.filter((tasksettings) => tasksettings.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
