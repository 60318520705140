import axios from "axios";
import { Direction } from "../../../../domain/occupancy_service/direction/direction";
import { Configuration, DirectionsApi, DirectionDirectionResponse } from "../../generated/occupancyApi";

export class DirectionsRepository {
    private service: DirectionsApi;

    constructor() {
        this.service = new DirectionsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadDirections(): Promise<Direction[] | Error> {
        try {
            let response = await this.service.apiOccupancyDirectionGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let directions : Direction[] = []
                response.data?.map((directionApi) => {
                    let direction = this.toDomainDirection(directionApi);
                    directions.push(direction)
                })
                return directions
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateDirection(name: string,): Promise<Direction | Error> {
        try {
            let response = await this.service.apiOccupancyDirectionPost({ name: name, }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
                let direction = this.toDomainDirection(response.data);
                return direction
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateDirection(id: string,name: string,): Promise<Direction | Error> {
        try {

            let response = await this.service.apiOccupancyDirectionIdPut(id, { name: name, }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let direction = this.toDomainDirection(response.data);
                return direction
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadDirectionById(id: UniqueId): Promise<Direction | Error> {
        try {

            let response = await this.service.apiOccupancyDirectionIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let direction = this.toDomainDirection(response.data);
                return direction
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeleteDirectionById(id: UniqueId): Promise<string | Error> {
        try {

            let response = await this.service.apiOccupancyDirectionIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return id
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    

    toDomainDirection(direction: DirectionDirectionResponse): Direction {
        return new Direction( direction.id!, direction.name!, new Date(direction.created_at!), new Date(direction.modified_at!), );
    }
    
}