import axios from "axios";


import { TaskReport } from "../../../../domain/occupancy_service/taskreport/taskreport";
import { ReportApi } from "../../generated/occupancyApi";
import { Configuration, ReportReportResponse } from "../../generated/occupancyApi";

export class ReportRepository {
    private service: ReportApi;

    constructor() {
        this.service = new ReportApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadTaskReports(from: Date, to: Date): Promise<{[key: string]: TaskReport[]} | Error> {
        try {
            let response = await this.service.apiOccupancyReportFromToGet(formatDate(from), formatDate(to), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let taskreports : {[key: string]: TaskReport[]} = {}
                Object.keys(response.data!).map((direction) => {
                    taskreports[direction] = []
                    response.data[direction].map((taskreportApi)=> {
                        let taskreport = this.toDomainTaskReport(taskreportApi);
                        taskreports[direction].push(taskreport)
                    })
                })
                return taskreports
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DownloadReport(from: Date, to: Date): Promise<string | Error> {
        try {
            let response = await this.service.apiOccupancyReportFromToXlsxGet(formatDate(from), formatDate(to), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                
                return "ok"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadLiveOccupancyReport(): Promise<{[key: string]: TaskReport[]} | Error> {
        try {
            let response = await this.service.apiOccupancyReportLiveGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let taskreports : {[key: string]: TaskReport[]} = {}
                Object.keys(response.data!).map((direction) => {
                    taskreports[direction] = []
                    response.data[direction].map((taskreportApi)=> {
                        let taskreport = this.toDomainTaskReport(taskreportApi);
                        taskreports[direction].push(taskreport)
                    })
                })
                return taskreports
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    toDomainTaskReport(report: ReportReportResponse): TaskReport {
        return new TaskReport( report.id!, new Date(report.date!), report.atc_name!, report.task_name!, report.direction!, report.operators_count!, report.occupancy!, report.etalon_occupancy!, report.operator_call_count!, report.recommended_calls_count_by_occupancy!, report.updates!, );
    }
    
}

const formatDate = (day: Date) => {
    if (day.getFullYear() === 1) {
        return ""
    }
    const yyyy = day.getFullYear();
    let mm = (day.getMonth() + 1).toString(); // Months start at 0!
    let dd = day.getDate().toString();

    if (dd.length == 1) dd = '0' + dd;
    if (mm.length == 1) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy.toString();
}
