/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface AuthSignInResponse
 */
export interface AuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface CallCallListResponse
 */
export interface CallCallListResponse {
    /**
     * 
     * @type {number}
     * @memberof CallCallListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<CallCallResponse>}
     * @memberof CallCallListResponse
     */
    'list'?: Array<CallCallResponse>;
}
/**
 * 
 * @export
 * @interface CallCallResponse
 */
export interface CallCallResponse {
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'call_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof CallCallResponse
     */
    'call_length'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'call_link'?: string;
    /**
     * 
     * @type {DomainCallType}
     * @memberof CallCallResponse
     */
    'call_type'?: DomainCallType;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'communication_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'entity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CallCallResponse
     */
    'lead_stage'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'operator_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CallCallResponse
     */
    'pipeline_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallCallResponse
     */
    'task_name'?: string;
}
/**
 * 
 * @export
 * @interface CallGetLocalLinkForAudioResponse
 */
export interface CallGetLocalLinkForAudioResponse {
    /**
     * 
     * @type {string}
     * @memberof CallGetLocalLinkForAudioResponse
     */
    'link'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DomainCallType = {
    Incoming: 'incoming',
    Outgoing: 'outgoing',
    NotDefined: 'not_defined'
} as const;

export type DomainCallType = typeof DomainCallType[keyof typeof DomainCallType];


/**
 * 
 * @export
 * @interface LeadgenStatus
 */
export interface LeadgenStatus {
    /**
     * 
     * @type {number}
     * @memberof LeadgenStatus
     */
    'pipelineId': number;
    /**
     * 
     * @type {string}
     * @memberof LeadgenStatus
     */
    'pipelineName': string;
    /**
     * 
     * @type {number}
     * @memberof LeadgenStatus
     */
    'statusId': number;
    /**
     * 
     * @type {string}
     * @memberof LeadgenStatus
     */
    'statusName': string;
}
/**
 * 
 * @export
 * @interface RestErrorResponse
 */
export interface RestErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof RestErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UserChangePasswordRequest
 */
export interface UserChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserChangePasswordRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface UserCreateUserRequest
 */
export interface UserCreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'role'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateUserRequest
 */
export interface UserUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserUserResponse
 */
export interface UserUserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'role'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiAuthSignInPost', 'user', user)
            const localVarPath = `/api/auth/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<AuthSignInResponse> {
            return localVarFp.apiAuthSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CallApi - axios parameter creator
 * @export
 */
export const CallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить отфильтрованный список звонков.
         * @summary получить отфильтрованный список звонков.
         * @param {number} offset Начальный индекс
         * @param {number} limit Лимит
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {string} [entityId] id лида
         * @param {Array<string>} [taskName] название задачи
         * @param {string} [callType] тип звонка
         * @param {Array<string>} [operatorName] имя оператора
         * @param {string} [pipelineId] id воронки
         * @param {string} [leadStage] id статуса амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallGet: async (offset: number, limit: number, from?: string, to?: string, entityId?: string, taskName?: Array<string>, callType?: string, operatorName?: Array<string>, pipelineId?: string, leadStage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('apiCallGet', 'offset', offset)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('apiCallGet', 'limit', limit)
            const localVarPath = `/api/call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (taskName) {
                localVarQueryParameter['task_name[]'] = taskName.join(COLLECTION_FORMATS.csv);
            }

            if (callType !== undefined) {
                localVarQueryParameter['call_type'] = callType;
            }

            if (operatorName) {
                localVarQueryParameter['operator_name[]'] = operatorName.join(COLLECTION_FORMATS.csv);
            }

            if (pipelineId !== undefined) {
                localVarQueryParameter['pipeline_id'] = pipelineId;
            }

            if (leadStage !== undefined) {
                localVarQueryParameter['lead_stage'] = leadStage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * скачать звонок (иначе не работает seek в аудио).
         * @summary скачать звонок (иначе не работает seek в аудио).
         * @param {string} url Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallLinkGet: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('apiCallLinkGet', 'url', url)
            const localVarPath = `/api/call/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список операторов.
         * @summary получить список операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallOperatorsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/call/operators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * скачать звонок (иначе не работает seek в аудио).
         * @summary скачать звонок (иначе не работает seek в аудио).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallStatusesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/call/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список задач.
         * @summary получить список задач.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallTasksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/call/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить отфильтрованный список звонков.
         * @summary получить отфильтрованный список звонков.
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {string} [entityId] id лида
         * @param {Array<string>} [taskName] название задачи
         * @param {string} [callType] тип звонка
         * @param {Array<string>} [operatorName] имя оператора
         * @param {string} [pipelineId] id воронки
         * @param {string} [leadStage] id статуса амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallXlsxGet: async (from?: string, to?: string, entityId?: string, taskName?: Array<string>, callType?: string, operatorName?: Array<string>, pipelineId?: string, leadStage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/call/xlsx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }

            if (taskName) {
                localVarQueryParameter['task_name[]'] = taskName.join(COLLECTION_FORMATS.csv);
            }

            if (callType !== undefined) {
                localVarQueryParameter['call_type'] = callType;
            }

            if (operatorName) {
                localVarQueryParameter['operator_name[]'] = operatorName.join(COLLECTION_FORMATS.csv);
            }

            if (pipelineId !== undefined) {
                localVarQueryParameter['pipeline_id'] = pipelineId;
            }

            if (leadStage !== undefined) {
                localVarQueryParameter['lead_stage'] = leadStage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallApi - functional programming interface
 * @export
 */
export const CallApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CallApiAxiosParamCreator(configuration)
    return {
        /**
         * получить отфильтрованный список звонков.
         * @summary получить отфильтрованный список звонков.
         * @param {number} offset Начальный индекс
         * @param {number} limit Лимит
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {string} [entityId] id лида
         * @param {Array<string>} [taskName] название задачи
         * @param {string} [callType] тип звонка
         * @param {Array<string>} [operatorName] имя оператора
         * @param {string} [pipelineId] id воронки
         * @param {string} [leadStage] id статуса амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCallGet(offset: number, limit: number, from?: string, to?: string, entityId?: string, taskName?: Array<string>, callType?: string, operatorName?: Array<string>, pipelineId?: string, leadStage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallCallListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCallGet(offset, limit, from, to, entityId, taskName, callType, operatorName, pipelineId, leadStage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * скачать звонок (иначе не работает seek в аудио).
         * @summary скачать звонок (иначе не работает seek в аудио).
         * @param {string} url Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCallLinkGet(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallGetLocalLinkForAudioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCallLinkGet(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список операторов.
         * @summary получить список операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCallOperatorsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCallOperatorsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * скачать звонок (иначе не работает seek в аудио).
         * @summary скачать звонок (иначе не работает seek в аудио).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCallStatusesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadgenStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCallStatusesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список задач.
         * @summary получить список задач.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCallTasksGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCallTasksGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить отфильтрованный список звонков.
         * @summary получить отфильтрованный список звонков.
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {string} [entityId] id лида
         * @param {Array<string>} [taskName] название задачи
         * @param {string} [callType] тип звонка
         * @param {Array<string>} [operatorName] имя оператора
         * @param {string} [pipelineId] id воронки
         * @param {string} [leadStage] id статуса амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCallXlsxGet(from?: string, to?: string, entityId?: string, taskName?: Array<string>, callType?: string, operatorName?: Array<string>, pipelineId?: string, leadStage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCallXlsxGet(from, to, entityId, taskName, callType, operatorName, pipelineId, leadStage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CallApi - factory interface
 * @export
 */
export const CallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CallApiFp(configuration)
    return {
        /**
         * получить отфильтрованный список звонков.
         * @summary получить отфильтрованный список звонков.
         * @param {number} offset Начальный индекс
         * @param {number} limit Лимит
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {string} [entityId] id лида
         * @param {Array<string>} [taskName] название задачи
         * @param {string} [callType] тип звонка
         * @param {Array<string>} [operatorName] имя оператора
         * @param {string} [pipelineId] id воронки
         * @param {string} [leadStage] id статуса амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallGet(offset: number, limit: number, from?: string, to?: string, entityId?: string, taskName?: Array<string>, callType?: string, operatorName?: Array<string>, pipelineId?: string, leadStage?: string, options?: any): AxiosPromise<CallCallListResponse> {
            return localVarFp.apiCallGet(offset, limit, from, to, entityId, taskName, callType, operatorName, pipelineId, leadStage, options).then((request) => request(axios, basePath));
        },
        /**
         * скачать звонок (иначе не работает seek в аудио).
         * @summary скачать звонок (иначе не работает seek в аудио).
         * @param {string} url Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallLinkGet(url: string, options?: any): AxiosPromise<CallGetLocalLinkForAudioResponse> {
            return localVarFp.apiCallLinkGet(url, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список операторов.
         * @summary получить список операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallOperatorsGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiCallOperatorsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * скачать звонок (иначе не работает seek в аудио).
         * @summary скачать звонок (иначе не работает seek в аудио).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallStatusesGet(options?: any): AxiosPromise<Array<LeadgenStatus>> {
            return localVarFp.apiCallStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * получить список задач.
         * @summary получить список задач.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallTasksGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiCallTasksGet(options).then((request) => request(axios, basePath));
        },
        /**
         * получить отфильтрованный список звонков.
         * @summary получить отфильтрованный список звонков.
         * @param {string} [from] дата передачи лида от
         * @param {string} [to] дата передачи лида до
         * @param {string} [entityId] id лида
         * @param {Array<string>} [taskName] название задачи
         * @param {string} [callType] тип звонка
         * @param {Array<string>} [operatorName] имя оператора
         * @param {string} [pipelineId] id воронки
         * @param {string} [leadStage] id статуса амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallXlsxGet(from?: string, to?: string, entityId?: string, taskName?: Array<string>, callType?: string, operatorName?: Array<string>, pipelineId?: string, leadStage?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiCallXlsxGet(from, to, entityId, taskName, callType, operatorName, pipelineId, leadStage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CallApi - object-oriented interface
 * @export
 * @class CallApi
 * @extends {BaseAPI}
 */
export class CallApi extends BaseAPI {
    /**
     * получить отфильтрованный список звонков.
     * @summary получить отфильтрованный список звонков.
     * @param {number} offset Начальный индекс
     * @param {number} limit Лимит
     * @param {string} [from] дата передачи лида от
     * @param {string} [to] дата передачи лида до
     * @param {string} [entityId] id лида
     * @param {Array<string>} [taskName] название задачи
     * @param {string} [callType] тип звонка
     * @param {Array<string>} [operatorName] имя оператора
     * @param {string} [pipelineId] id воронки
     * @param {string} [leadStage] id статуса амо
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallApi
     */
    public apiCallGet(offset: number, limit: number, from?: string, to?: string, entityId?: string, taskName?: Array<string>, callType?: string, operatorName?: Array<string>, pipelineId?: string, leadStage?: string, options?: AxiosRequestConfig) {
        return CallApiFp(this.configuration).apiCallGet(offset, limit, from, to, entityId, taskName, callType, operatorName, pipelineId, leadStage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * скачать звонок (иначе не работает seek в аудио).
     * @summary скачать звонок (иначе не работает seek в аудио).
     * @param {string} url Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallApi
     */
    public apiCallLinkGet(url: string, options?: AxiosRequestConfig) {
        return CallApiFp(this.configuration).apiCallLinkGet(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список операторов.
     * @summary получить список операторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallApi
     */
    public apiCallOperatorsGet(options?: AxiosRequestConfig) {
        return CallApiFp(this.configuration).apiCallOperatorsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * скачать звонок (иначе не работает seek в аудио).
     * @summary скачать звонок (иначе не работает seek в аудио).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallApi
     */
    public apiCallStatusesGet(options?: AxiosRequestConfig) {
        return CallApiFp(this.configuration).apiCallStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список задач.
     * @summary получить список задач.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallApi
     */
    public apiCallTasksGet(options?: AxiosRequestConfig) {
        return CallApiFp(this.configuration).apiCallTasksGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить отфильтрованный список звонков.
     * @summary получить отфильтрованный список звонков.
     * @param {string} [from] дата передачи лида от
     * @param {string} [to] дата передачи лида до
     * @param {string} [entityId] id лида
     * @param {Array<string>} [taskName] название задачи
     * @param {string} [callType] тип звонка
     * @param {Array<string>} [operatorName] имя оператора
     * @param {string} [pipelineId] id воронки
     * @param {string} [leadStage] id статуса амо
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallApi
     */
    public apiCallXlsxGet(from?: string, to?: string, entityId?: string, taskName?: Array<string>, callType?: string, operatorName?: Array<string>, pipelineId?: string, leadStage?: string, options?: AxiosRequestConfig) {
        return CallApiFp(this.configuration).apiCallXlsxGet(from, to, entityId, taskName, callType, operatorName, pipelineId, leadStage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdDelete', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdGet', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPasswordPut: async (id: string, user: UserChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPasswordPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPasswordPut', 'user', user)
            const localVarPath = `/api/user/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut: async (id: string, user: UserUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPut', 'user', user)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (user: UserCreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserPost', 'user', user)
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPasswordPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<UserUserResponse>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPasswordPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(user: UserCreateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * получить список всех источников.
     * @summary получить список всех пользователей.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить пользователя
     * @summary удалить пользователя
     * @param {string} id Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить платеж по id.
     * @summary получить платеж по id.
     * @param {string} id Идентификатор платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * смена пароля пользователя.
     * @summary смена пароля пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserChangePasswordRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPasswordPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление пользователя.
     * @summary обновление пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserUpdateUserRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание пользователя.
     * @summary создание пользователя.
     * @param {UserCreateUserRequest} user Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


