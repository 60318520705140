import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import Multiselect from 'multiselect-react-dropdown';
import { ReportReportGroup } from "../../../../repository/api/generated/timeApi"

export const TimeReport = () => {
    let { id } = useParams();
    const [countOnPage, setCountOnPage] = useState(50)

    const [report, setReport] = useState<ReportReportGroup[]>([])

    const [pagination, setPagination] = useState<{ offset: number, limit: number }>({
        offset: 0,
        limit: countOnPage,
    })

    const getYesterday = () => {
        var date = new Date();
        date.setDate(date.getDate() - 1);
        return date
    }

    const [filter, setFilter] = useState<{
        from: Date,
        to: Date,
    }>({
        from: getYesterday(),
        to: getYesterday(),
    })



    let useCases = useContext(UseCasesContext)


    const calls = useTypedSelector(({ call }) => {
        return call
    })


    useEffect(() => {
        if (pagination) {
            setPagination({
                ...pagination,
                offset: 0,
                limit: countOnPage!,
            })
        }
    }, [countOnPage])

    const readTimeReport = async () => {
        let response = await useCases?.timeUseCase.ReadTimeReport(filter.from, filter.to)
        if (response instanceof Error) {

        } else {
            setReport(response!)
        }
    }

    useEffect(() => {
        setReport([])
        readTimeReport()
    }, [filter])

    const formatDateForInput = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (filter) {
            setFilter({
                ...filter,
                [e.target.name]: new Date(e.target.value)
            })
        }
    }

    const inputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        let value: any = e.target.value
        if (e.target.type === "number") {
            if (e.target.value === "") {
                value = 0
            } else {
                value = parseInt(e.target.value)
            }
        }
        setFilter({
            ...filter,
            [e.target.name]: value,
        })
    }

    const formatDateMonthAndDay = (day: Date) => {
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm;
    }

    const downloadFile = async () => {
        if (filter) {
            let file = await useCases?.timeUseCase.ReadTimeReportXslx(filter.from, filter.to)

            if (file instanceof Error) {

            } else {

                var link = document.createElement('a');
                link.href = "/api/time_report/files/tmp.xlsx";
                link.target = "_blank"
                link.download = "timereport_"+formatDateMonthAndDay(filter.from)+"-"+formatDateMonthAndDay(filter.to)+".xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Учет времени /</span> Отчет</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"*"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" onClick={(e) => { e.preventDefault(); downloadFile() }}><span>Скачать</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-4" style={{ zIndex: 800 }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="form-label">Дата от:</label>
                                        <input type="date" value={filter?.from ? formatDateForInput(filter?.from!) : undefined} name="from" onChange={dateChange} className="form-control dt-input dt-full-name" />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">Дата по:</label>
                                        <input type="date" value={filter?.to ? formatDateForInput(filter?.to!) : undefined} name="to" onChange={dateChange} className="form-control dt-input dt-full-name" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="table-responsive text-nowrap table_time">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th style={{width:"10px"}}>-</th>
                                            <th>Группа</th>
                                            <th style={{width: "5%"}}>%Пост</th>
                                            <th style={{width: "5%"}}>Util</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            report.sort((a:ReportReportGroup, b: ReportReportGroup) => a.leader_name! > b.leader_name! ? 1 : -1).map((groupReport, index) => {
                                                return <TableRow report={groupReport} oneDayReport ={filter.to.getDate() == filter.from.getDate()} last={report!.length - 1 == index ? true : false} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}