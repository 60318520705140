import axios from "axios";
import { User } from "../../../domain/user/user";
import { Configuration, UserUserResponse, UsersApi } from "../generated/callApi";
import { UsersInterface } from "../../../useCase/users/adapters/interface";

export class UsersRepository implements UsersInterface {
    private service: UsersApi;

    constructor() {
        this.service = new UsersApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadUserById(id: UniqueId): Promise<User | Error> {
        try {
            let response = await this.service.apiUserIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainUser(response.data)

            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
    
    async ReadUsers(): Promise<User[] | Error> {
        try {
            let response = await this.service.apiUserGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let users: User[] = []
                response.data.forEach(userData => {

                    let user = this.toDomainUser(userData)
                    users.push(user)
                });
                return users
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteUser(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiUserIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateUser(name: string, login: string, pass: string, role: string,): Promise<User | Error> {
        try {
            let response = await this.service.apiUserPost({name: name, login: login, pass: pass, role: role,}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return this.toDomainUser(response.data)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateUser(id: UniqueId, name: string, login: string): Promise<User | Error> {
        try {
            let response = await this.service.apiUserIdPut(id, {name: name, login: login}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               return this.toDomainUser(response.data)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ChangePassword(id: UniqueId, pass: string): Promise<User | Error> {
        try {
            let response = await this.service.apiUserIdPasswordPut(id, {password: pass}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               
                return this.toDomainUser(response.data)
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    toDomainUser = (user: UserUserResponse) => {
        return new User(user.id!, user.name!, user.login!, user.pass!, user.role!,  new Date(user.created_at!), new Date(user.modified_at!))
    }

}