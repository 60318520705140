import { TimeReportRepository } from "../../repository/api/time/time";
import { actionCreators } from "../../state";

export class TimeUseCases {
    private api: TimeReportRepository;
    private stateActions: typeof actionCreators;

    constructor(api: TimeReportRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }


    public ReadTimeReport = async (from: Date, to: Date) => {
        let response = await this.api.ReadTimeReport(from, to)
        return response
    }


    public ReadTimeReportXslx = async (from: Date, to: Date) => {
        let response = await this.api.ReadTimeReportXlsx(from, to)
        return response
    }

}
