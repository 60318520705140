import axios from "axios";
import { Atc } from "../../../../domain/occupancy_service/atc/atc";
import { Configuration, AtcsApi, AtcAtcResponse } from "../../generated/occupancyApi";

export class AtcsRepository {
    private service: AtcsApi;

    constructor() {
        this.service = new AtcsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadAtcs(): Promise<Atc[] | Error> {
        try {
            let response = await this.service.apiOccupancyAtcGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let atcs : Atc[] = []
                response.data?.map((atcApi) => {
                    let atc = this.toDomainAtc(atcApi);
                    atcs.push(atc)
                })
                return atcs
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateAtc(name: string, baseUrl: string,): Promise<Atc | Error> {
        try {
            let response = await this.service.apiOccupancyAtcPost({ name: name, base_url: baseUrl }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
                let atc = this.toDomainAtc(response.data);
                return atc
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateAtc(id: string,name: string, baseUrl: string): Promise<Atc | Error> {
        try {

            let response = await this.service.apiOccupancyAtcIdPut(id, { name: name, base_url: baseUrl }, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let atc = this.toDomainAtc(response.data);
                return atc
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async ReadAtcById(id: UniqueId): Promise<Atc | Error> {
        try {

            let response = await this.service.apiOccupancyAtcIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let atc = this.toDomainAtc(response.data);
                return atc
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeleteAtcById(id: UniqueId): Promise<string | Error> {
        try {

            let response = await this.service.apiOccupancyAtcIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return id
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    

    toDomainAtc(atc: AtcAtcResponse): Atc {
        return new Atc( atc.id!, atc.name!, atc.base_url!, new Date(atc.created_at!), new Date(atc.modified_at!), );
    }
    
}