export class Direction {
     
    private id: string; 
    private name: string; 
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: string, name: string, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.name = name;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): string => {
        return this.id
    }
    public Name = (): string => {
        return this.name
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}