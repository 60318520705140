
import { ReportRepository } from "../../../repository/api/occupancy_service/report/taskreport";
import { actionCreators } from "../../../state";

export class ReportUseCases {
    private api: ReportRepository;
    private stateActions: typeof actionCreators;

    constructor(api: ReportRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadReport = async (from: Date, to: Date) => {
        let response = await this.api.ReadTaskReports(from, to)
        return response
    }

    public DownloadReport = async (from: Date, to: Date) => {
        let response = await this.api.DownloadReport(from, to)
        return response
    }

    public ReadLiveReport = async () => {
        let response = await this.api.ReadLiveOccupancyReport()
        return response
    }

}