import { useRef, useState } from "react";
import { Direction } from "../../../../../domain/occupancy_service/direction/direction";
import { DotsVertical, Edit } from "tabler-icons-react";
import { Link } from "react-router-dom";

export const TableRow = (props: { direction: Direction, last: boolean }) => {
    const [showMenu, setShowMenu] = useState(false)
    const rowRef = useRef<any>(null);
    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();
        let hh = day.getHours().toString();
        let mins = day.getMinutes().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;
        if (hh.length == 1) hh = '0' + hh;
        if (mins.length == 1) mins = '0' + mins;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2) + " " + hh + ":" + mins;
    }



    return (
        <div key={"lead_" + props.direction.Id()} className="css_tr" ref={rowRef} onMouseLeave={() => { console.log("leave"); }}>
            <div className="css_sd css_sd_nowrap">{props.direction.Name()}</div>
            <div className="css_sd css_sd_nowrap">{formatDate(props.direction.CreatedAt())}</div>
            
            <div className="dropdown">
                <button type="button" onClick={()=>{setShowMenu(!showMenu)}} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <DotsVertical />
                </button>
                <div className={showMenu?"dropdown-menu show":"dropdown-menu "} style={showMenu ? props.last ? { position: "absolute", inset: "auto auto 0px -20%", margin: "0px", transform: "translate3d(0px, -20.5px, 0px)" }: {position: "absolute", marginLeft: "-40%",}: {display:"none"}}>
                    <Link className="dropdown-item d-flex" to={"/direction/edit/"+props.direction.Id()}><Edit className="me-1" size={20} /> Редактировать</Link>
                </div>
            </div>
        </div>
    )
}