/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const DomainBreakType = {
    Dinner: 'Обед',
    Planned: 'Плановый',
    Study: 'Обучение',
    Tech: 'Технический',
    DopTasks: 'Доп. задачи'
} as const;

export type DomainBreakType = typeof DomainBreakType[keyof typeof DomainBreakType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DomainState = {
    System: 0,
    CallCenter: 1,
    Online: 2,
    Break: 3
} as const;

export type DomainState = typeof DomainState[keyof typeof DomainState];


/**
 * 
 * @export
 * @interface OperatorTimingCreateOperatorTimingRequest
 */
export interface OperatorTimingCreateOperatorTimingRequest {
    /**
     * 
     * @type {DomainBreakType}
     * @memberof OperatorTimingCreateOperatorTimingRequest
     */
    'break_type'?: DomainBreakType;
    /**
     * 
     * @type {number}
     * @memberof OperatorTimingCreateOperatorTimingRequest
     */
    'date'?: number;
    /**
     * 
     * @type {number}
     * @memberof OperatorTimingCreateOperatorTimingRequest
     */
    'lengh'?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorTimingCreateOperatorTimingRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorTimingCreateOperatorTimingRequest
     */
    'operator'?: string;
    /**
     * 
     * @type {DomainState}
     * @memberof OperatorTimingCreateOperatorTimingRequest
     */
    'state'?: DomainState;
}
/**
 * 
 * @export
 * @interface ReportDopGroupCalcFields
 */
export interface ReportDopGroupCalcFields {
    /**
     * 
     * @type {Array<ReportOperatorDayStatResponse>}
     * @memberof ReportDopGroupCalcFields
     */
    'day_stats'?: Array<ReportOperatorDayStatResponse>;
    /**
     * 
     * @type {number}
     * @memberof ReportDopGroupCalcFields
     */
    'in_call_sum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDopGroupCalcFields
     */
    'in_post_sum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDopGroupCalcFields
     */
    'online_sum'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDopGroupCalcFields
     */
    'post'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportDopGroupCalcFields
     */
    'utilization'?: number;
}
/**
 * 
 * @export
 * @interface ReportOperatorDayStatResponse
 */
export interface ReportOperatorDayStatResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportOperatorDayStatResponse
     */
    'data'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'dinner_break'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'dop_tasks_break'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOperatorDayStatResponse
     */
    'exit_call_center'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportOperatorDayStatResponse
     */
    'fio'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'in_call'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'in_post'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'in_system'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOperatorDayStatResponse
     */
    'login'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'on_line'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'other_break'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'planned_break'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'post_percent'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportOperatorDayStatResponse
     */
    'start'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'study_break'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'sum_of_breaks'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'sum_of_online_and_breaks'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'tech_break'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOperatorDayStatResponse
     */
    'utilization'?: number;
}
/**
 * 
 * @export
 * @interface ReportReportGroup
 */
export interface ReportReportGroup {
    /**
     * 
     * @type {{ [key: string]: ReportDopGroupCalcFields; }}
     * @memberof ReportReportGroup
     */
    'detalization'?: { [key: string]: ReportDopGroupCalcFields; };
    /**
     * 
     * @type {string}
     * @memberof ReportReportGroup
     */
    'leader_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportReportGroup
     */
    'post'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportReportGroup
     */
    'utilization'?: number;
}
/**
 * 
 * @export
 * @interface RestErrorResponse
 */
export interface RestErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof RestErrorResponse
     */
    'message'?: string;
}

/**
 * OperatorTimingsApi - axios parameter creator
 * @export
 */
export const OperatorTimingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * создание списка operatorTiming.
         * @summary создание списка operatorTiming.
         * @param {Array<OperatorTimingCreateOperatorTimingRequest>} operatorTiming Данные operatorTiming
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeReportTimingsPost: async (operatorTiming: Array<OperatorTimingCreateOperatorTimingRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operatorTiming' is not null or undefined
            assertParamExists('apiTimeReportTimingsPost', 'operatorTiming', operatorTiming)
            const localVarPath = `/api/time_report/timings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operatorTiming, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperatorTimingsApi - functional programming interface
 * @export
 */
export const OperatorTimingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperatorTimingsApiAxiosParamCreator(configuration)
    return {
        /**
         * создание списка operatorTiming.
         * @summary создание списка operatorTiming.
         * @param {Array<OperatorTimingCreateOperatorTimingRequest>} operatorTiming Данные operatorTiming
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTimeReportTimingsPost(operatorTiming: Array<OperatorTimingCreateOperatorTimingRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimeReportTimingsPost(operatorTiming, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperatorTimingsApi - factory interface
 * @export
 */
export const OperatorTimingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperatorTimingsApiFp(configuration)
    return {
        /**
         * создание списка operatorTiming.
         * @summary создание списка operatorTiming.
         * @param {Array<OperatorTimingCreateOperatorTimingRequest>} operatorTiming Данные operatorTiming
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeReportTimingsPost(operatorTiming: Array<OperatorTimingCreateOperatorTimingRequest>, options?: any): AxiosPromise<void> {
            return localVarFp.apiTimeReportTimingsPost(operatorTiming, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperatorTimingsApi - object-oriented interface
 * @export
 * @class OperatorTimingsApi
 * @extends {BaseAPI}
 */
export class OperatorTimingsApi extends BaseAPI {
    /**
     * создание списка operatorTiming.
     * @summary создание списка operatorTiming.
     * @param {Array<OperatorTimingCreateOperatorTimingRequest>} operatorTiming Данные operatorTiming
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorTimingsApi
     */
    public apiTimeReportTimingsPost(operatorTiming: Array<OperatorTimingCreateOperatorTimingRequest>, options?: AxiosRequestConfig) {
        return OperatorTimingsApiFp(this.configuration).apiTimeReportTimingsPost(operatorTiming, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeReportReportFromToGet: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('apiTimeReportReportFromToGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('apiTimeReportReportFromToGet', 'to', to)
            const localVarPath = `/api/time_report/report/{from}/{to}`
                .replace(`{${"from"}}`, encodeURIComponent(String(from)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeReportReportFromToXlsxGet: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('apiTimeReportReportFromToXlsxGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('apiTimeReportReportFromToXlsxGet', 'to', to)
            const localVarPath = `/api/time_report/report/{from}/{to}/xlsx`
                .replace(`{${"from"}}`, encodeURIComponent(String(from)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTimeReportReportFromToGet(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportReportGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimeReportReportFromToGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTimeReportReportFromToXlsxGet(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimeReportReportFromToXlsxGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeReportReportFromToGet(from: string, to: string, options?: any): AxiosPromise<Array<ReportReportGroup>> {
            return localVarFp.apiTimeReportReportFromToGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeReportReportFromToXlsxGet(from: string, to: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTimeReportReportFromToXlsxGet(from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * сформировать отчет.
     * @summary сформировать отчет.
     * @param {string} from дата от
     * @param {string} to дата по
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiTimeReportReportFromToGet(from: string, to: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiTimeReportReportFromToGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * сформировать отчет.
     * @summary сформировать отчет.
     * @param {string} from дата от
     * @param {string} to дата по
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiTimeReportReportFromToXlsxGet(from: string, to: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiTimeReportReportFromToXlsxGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


