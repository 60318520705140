import axios from "axios";
import { Update } from "../../../../domain/occupancy_service/update/update";
import { Configuration, UpdatesApi, UpdateUpdateResponse } from "../../generated/occupancyApi";

export class UpdatesRepository {
    private service: UpdatesApi;

    constructor() {
        this.service = new UpdatesApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadUpdates(): Promise<Update[] | Error> {
        try {
            let response = await this.service.apiOccupancyUpdateGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let updates : Update[] = []
                response.data?.map((updateApi) => {
                    let update = this.toDomainUpdate(updateApi);
                    updates.push(update)
                })
                return updates
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
    

    toDomainUpdate(update: UpdateUpdateResponse): Update {
        return new Update( update.id!, new Date(update.date!), update.task_settings_id!, update.old_value!, update.new_value!, update.result!, update.result_message!, new Date(update.created_at!), new Date(update.modified_at!), );
    }
    
}