/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AtcAtcResponse
 */
export interface AtcAtcResponse {
    /**
     * 
     * @type {string}
     * @memberof AtcAtcResponse
     */
    'base_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtcAtcResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtcAtcResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtcAtcResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtcAtcResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AtcCreateAtcRequest
 */
export interface AtcCreateAtcRequest {
    /**
     * 
     * @type {string}
     * @memberof AtcCreateAtcRequest
     */
    'base_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtcCreateAtcRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AtcUpdateAtcRequest
 */
export interface AtcUpdateAtcRequest {
    /**
     * 
     * @type {string}
     * @memberof AtcUpdateAtcRequest
     */
    'base_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtcUpdateAtcRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DirectionCreateDirectionRequest
 */
export interface DirectionCreateDirectionRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectionCreateDirectionRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DirectionDirectionResponse
 */
export interface DirectionDirectionResponse {
    /**
     * 
     * @type {string}
     * @memberof DirectionDirectionResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDirectionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDirectionResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectionDirectionResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DirectionUpdateDirectionRequest
 */
export interface DirectionUpdateDirectionRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectionUpdateDirectionRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DomainUpdateItem
 */
export interface DomainUpdateItem {
    /**
     * 
     * @type {string}
     * @memberof DomainUpdateItem
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof DomainUpdateItem
     */
    'new_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainUpdateItem
     */
    'old_value'?: number;
    /**
     * 
     * @type {DomainUpdateResult}
     * @memberof DomainUpdateItem
     */
    'update_result'?: DomainUpdateResult;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DomainUpdateResult = {
    Pending: 'pending',
    Success: 'success',
    Error: 'error'
} as const;

export type DomainUpdateResult = typeof DomainUpdateResult[keyof typeof DomainUpdateResult];


/**
 * 
 * @export
 * @interface ReportReportResponse
 */
export interface ReportReportResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportReportResponse
     */
    'atc_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReportResponse
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReportResponse
     */
    'direction'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportReportResponse
     */
    'etalon_occupancy'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportReportResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportReportResponse
     */
    'occupancy'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportReportResponse
     */
    'operator_call_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportReportResponse
     */
    'operators_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportReportResponse
     */
    'recommended_calls_count_by_occupancy'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportReportResponse
     */
    'task_name'?: string;
    /**
     * 
     * @type {Array<DomainUpdateItem>}
     * @memberof ReportReportResponse
     */
    'updates'?: Array<DomainUpdateItem>;
}
/**
 * 
 * @export
 * @interface RestErrorResponse
 */
export interface RestErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof RestErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface SettingsCreateSettingsRequest
 */
export interface SettingsCreateSettingsRequest {
    /**
     * 
     * @type {number}
     * @memberof SettingsCreateSettingsRequest
     */
    'occupancy_check_period_in_minutes'?: number;
}
/**
 * 
 * @export
 * @interface SettingsSettingsResponse
 */
export interface SettingsSettingsResponse {
    /**
     * 
     * @type {string}
     * @memberof SettingsSettingsResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof SettingsSettingsResponse
     */
    'occupancy_check_period_in_minutes'?: number;
}
/**
 * 
 * @export
 * @interface TaskSettingsCreateTaskSettingsRequest
 */
export interface TaskSettingsCreateTaskSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsCreateTaskSettingsRequest
     */
    'atc_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsCreateTaskSettingsRequest
     */
    'direction_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskSettingsCreateTaskSettingsRequest
     */
    'etalon_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsCreateTaskSettingsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskSettingsCreateTaskSettingsRequest
     */
    'operators_call_count'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof TaskSettingsCreateTaskSettingsRequest
     */
    'recommended_calls_count_by_occupancy'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface TaskSettingsTaskSettingsResponse
 */
export interface TaskSettingsTaskSettingsResponse {
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'atc_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'direction_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'etalon_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'operators_call_count'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof TaskSettingsTaskSettingsResponse
     */
    'recommended_calls_count_by_occupancy'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface TaskSettingsUpdateTaskSettingsRequest
 */
export interface TaskSettingsUpdateTaskSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsUpdateTaskSettingsRequest
     */
    'atc_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsUpdateTaskSettingsRequest
     */
    'direction_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskSettingsUpdateTaskSettingsRequest
     */
    'etalon_percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskSettingsUpdateTaskSettingsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskSettingsUpdateTaskSettingsRequest
     */
    'operators_call_count'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof TaskSettingsUpdateTaskSettingsRequest
     */
    'recommended_calls_count_by_occupancy'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface UpdateCreateUpdateRequest
 */
export interface UpdateCreateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCreateUpdateRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCreateUpdateRequest
     */
    'new_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCreateUpdateRequest
     */
    'old_value'?: number;
    /**
     * 
     * @type {DomainUpdateResult}
     * @memberof UpdateCreateUpdateRequest
     */
    'result'?: DomainUpdateResult;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreateUpdateRequest
     */
    'result_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCreateUpdateRequest
     */
    'task_settings_id'?: string;
}
/**
 * 
 * @export
 * @interface UpdateOktellResultCallback
 */
export interface UpdateOktellResultCallback {
    /**
     * 
     * @type {number}
     * @memberof UpdateOktellResultCallback
     */
    'result'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOktellResultCallback
     */
    'task_name'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUpdateResponse
 */
export interface UpdateUpdateResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateResponse
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUpdateResponse
     */
    'new_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUpdateResponse
     */
    'old_value'?: number;
    /**
     * 
     * @type {DomainUpdateResult}
     * @memberof UpdateUpdateResponse
     */
    'result'?: DomainUpdateResult;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateResponse
     */
    'result_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateResponse
     */
    'task_settings_id'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUpdateUpdateRequest
 */
export interface UpdateUpdateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateUpdateRequest
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUpdateUpdateRequest
     */
    'new_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateUpdateUpdateRequest
     */
    'old_value'?: number;
    /**
     * 
     * @type {DomainUpdateResult}
     * @memberof UpdateUpdateUpdateRequest
     */
    'result'?: DomainUpdateResult;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateUpdateRequest
     */
    'result_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpdateUpdateRequest
     */
    'task_settings_id'?: string;
}

/**
 * AtcsApi - axios parameter creator
 * @export
 */
export const AtcsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех atc.
         * @summary получить список всех atc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/occupancy/atc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить atc
         * @summary удалить atc
         * @param {string} id Идентификатор atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyAtcIdDelete', 'id', id)
            const localVarPath = `/api/occupancy/atc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить atc по id.
         * @summary получить atc по id.
         * @param {string} id Идентификатор atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyAtcIdGet', 'id', id)
            const localVarPath = `/api/occupancy/atc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление atc.
         * @summary обновление atc.
         * @param {string} id Идентификатор atc
         * @param {AtcUpdateAtcRequest} atc Данные для обновления atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcIdPut: async (id: string, atc: AtcUpdateAtcRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyAtcIdPut', 'id', id)
            // verify required parameter 'atc' is not null or undefined
            assertParamExists('apiOccupancyAtcIdPut', 'atc', atc)
            const localVarPath = `/api/occupancy/atc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(atc, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание atc.
         * @summary создание atc.
         * @param {AtcCreateAtcRequest} atc Данные atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcPost: async (atc: AtcCreateAtcRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'atc' is not null or undefined
            assertParamExists('apiOccupancyAtcPost', 'atc', atc)
            const localVarPath = `/api/occupancy/atc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(atc, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AtcsApi - functional programming interface
 * @export
 */
export const AtcsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AtcsApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех atc.
         * @summary получить список всех atc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyAtcGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AtcAtcResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyAtcGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить atc
         * @summary удалить atc
         * @param {string} id Идентификатор atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyAtcIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AtcAtcResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyAtcIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить atc по id.
         * @summary получить atc по id.
         * @param {string} id Идентификатор atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyAtcIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AtcAtcResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyAtcIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление atc.
         * @summary обновление atc.
         * @param {string} id Идентификатор atc
         * @param {AtcUpdateAtcRequest} atc Данные для обновления atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyAtcIdPut(id: string, atc: AtcUpdateAtcRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AtcAtcResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyAtcIdPut(id, atc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание atc.
         * @summary создание atc.
         * @param {AtcCreateAtcRequest} atc Данные atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyAtcPost(atc: AtcCreateAtcRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AtcAtcResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyAtcPost(atc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AtcsApi - factory interface
 * @export
 */
export const AtcsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AtcsApiFp(configuration)
    return {
        /**
         * получить список всех atc.
         * @summary получить список всех atc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcGet(options?: any): AxiosPromise<Array<AtcAtcResponse>> {
            return localVarFp.apiOccupancyAtcGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить atc
         * @summary удалить atc
         * @param {string} id Идентификатор atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcIdDelete(id: string, options?: any): AxiosPromise<AtcAtcResponse> {
            return localVarFp.apiOccupancyAtcIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить atc по id.
         * @summary получить atc по id.
         * @param {string} id Идентификатор atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcIdGet(id: string, options?: any): AxiosPromise<AtcAtcResponse> {
            return localVarFp.apiOccupancyAtcIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление atc.
         * @summary обновление atc.
         * @param {string} id Идентификатор atc
         * @param {AtcUpdateAtcRequest} atc Данные для обновления atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcIdPut(id: string, atc: AtcUpdateAtcRequest, options?: any): AxiosPromise<AtcAtcResponse> {
            return localVarFp.apiOccupancyAtcIdPut(id, atc, options).then((request) => request(axios, basePath));
        },
        /**
         * создание atc.
         * @summary создание atc.
         * @param {AtcCreateAtcRequest} atc Данные atc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyAtcPost(atc: AtcCreateAtcRequest, options?: any): AxiosPromise<AtcAtcResponse> {
            return localVarFp.apiOccupancyAtcPost(atc, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AtcsApi - object-oriented interface
 * @export
 * @class AtcsApi
 * @extends {BaseAPI}
 */
export class AtcsApi extends BaseAPI {
    /**
     * получить список всех atc.
     * @summary получить список всех atc.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtcsApi
     */
    public apiOccupancyAtcGet(options?: AxiosRequestConfig) {
        return AtcsApiFp(this.configuration).apiOccupancyAtcGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить atc
     * @summary удалить atc
     * @param {string} id Идентификатор atc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtcsApi
     */
    public apiOccupancyAtcIdDelete(id: string, options?: AxiosRequestConfig) {
        return AtcsApiFp(this.configuration).apiOccupancyAtcIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить atc по id.
     * @summary получить atc по id.
     * @param {string} id Идентификатор atc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtcsApi
     */
    public apiOccupancyAtcIdGet(id: string, options?: AxiosRequestConfig) {
        return AtcsApiFp(this.configuration).apiOccupancyAtcIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление atc.
     * @summary обновление atc.
     * @param {string} id Идентификатор atc
     * @param {AtcUpdateAtcRequest} atc Данные для обновления atc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtcsApi
     */
    public apiOccupancyAtcIdPut(id: string, atc: AtcUpdateAtcRequest, options?: AxiosRequestConfig) {
        return AtcsApiFp(this.configuration).apiOccupancyAtcIdPut(id, atc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание atc.
     * @summary создание atc.
     * @param {AtcCreateAtcRequest} atc Данные atc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AtcsApi
     */
    public apiOccupancyAtcPost(atc: AtcCreateAtcRequest, options?: AxiosRequestConfig) {
        return AtcsApiFp(this.configuration).apiOccupancyAtcPost(atc, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DirectionsApi - axios parameter creator
 * @export
 */
export const DirectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех direction.
         * @summary получить список всех direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/occupancy/direction/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить direction
         * @summary удалить direction
         * @param {string} id Идентификатор direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyDirectionIdDelete', 'id', id)
            const localVarPath = `/api/occupancy/direction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить direction по id.
         * @summary получить direction по id.
         * @param {string} id Идентификатор direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyDirectionIdGet', 'id', id)
            const localVarPath = `/api/occupancy/direction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление direction.
         * @summary обновление direction.
         * @param {string} id Идентификатор direction
         * @param {DirectionUpdateDirectionRequest} direction Данные для обновления direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionIdPut: async (id: string, direction: DirectionUpdateDirectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyDirectionIdPut', 'id', id)
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiOccupancyDirectionIdPut', 'direction', direction)
            const localVarPath = `/api/occupancy/direction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(direction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание direction.
         * @summary создание direction.
         * @param {DirectionCreateDirectionRequest} direction Данные direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionPost: async (direction: DirectionCreateDirectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'direction' is not null or undefined
            assertParamExists('apiOccupancyDirectionPost', 'direction', direction)
            const localVarPath = `/api/occupancy/direction/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(direction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectionsApi - functional programming interface
 * @export
 */
export const DirectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех direction.
         * @summary получить список всех direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyDirectionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DirectionDirectionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyDirectionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить direction
         * @summary удалить direction
         * @param {string} id Идентификатор direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyDirectionIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionDirectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyDirectionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить direction по id.
         * @summary получить direction по id.
         * @param {string} id Идентификатор direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyDirectionIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionDirectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyDirectionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление direction.
         * @summary обновление direction.
         * @param {string} id Идентификатор direction
         * @param {DirectionUpdateDirectionRequest} direction Данные для обновления direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyDirectionIdPut(id: string, direction: DirectionUpdateDirectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionDirectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyDirectionIdPut(id, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание direction.
         * @summary создание direction.
         * @param {DirectionCreateDirectionRequest} direction Данные direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyDirectionPost(direction: DirectionCreateDirectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectionDirectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyDirectionPost(direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectionsApi - factory interface
 * @export
 */
export const DirectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectionsApiFp(configuration)
    return {
        /**
         * получить список всех direction.
         * @summary получить список всех direction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionGet(options?: any): AxiosPromise<Array<DirectionDirectionResponse>> {
            return localVarFp.apiOccupancyDirectionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить direction
         * @summary удалить direction
         * @param {string} id Идентификатор direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionIdDelete(id: string, options?: any): AxiosPromise<DirectionDirectionResponse> {
            return localVarFp.apiOccupancyDirectionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить direction по id.
         * @summary получить direction по id.
         * @param {string} id Идентификатор direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionIdGet(id: string, options?: any): AxiosPromise<DirectionDirectionResponse> {
            return localVarFp.apiOccupancyDirectionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление direction.
         * @summary обновление direction.
         * @param {string} id Идентификатор direction
         * @param {DirectionUpdateDirectionRequest} direction Данные для обновления direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionIdPut(id: string, direction: DirectionUpdateDirectionRequest, options?: any): AxiosPromise<DirectionDirectionResponse> {
            return localVarFp.apiOccupancyDirectionIdPut(id, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * создание direction.
         * @summary создание direction.
         * @param {DirectionCreateDirectionRequest} direction Данные direction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyDirectionPost(direction: DirectionCreateDirectionRequest, options?: any): AxiosPromise<DirectionDirectionResponse> {
            return localVarFp.apiOccupancyDirectionPost(direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectionsApi - object-oriented interface
 * @export
 * @class DirectionsApi
 * @extends {BaseAPI}
 */
export class DirectionsApi extends BaseAPI {
    /**
     * получить список всех direction.
     * @summary получить список всех direction.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsApi
     */
    public apiOccupancyDirectionGet(options?: AxiosRequestConfig) {
        return DirectionsApiFp(this.configuration).apiOccupancyDirectionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить direction
     * @summary удалить direction
     * @param {string} id Идентификатор direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsApi
     */
    public apiOccupancyDirectionIdDelete(id: string, options?: AxiosRequestConfig) {
        return DirectionsApiFp(this.configuration).apiOccupancyDirectionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить direction по id.
     * @summary получить direction по id.
     * @param {string} id Идентификатор direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsApi
     */
    public apiOccupancyDirectionIdGet(id: string, options?: AxiosRequestConfig) {
        return DirectionsApiFp(this.configuration).apiOccupancyDirectionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление direction.
     * @summary обновление direction.
     * @param {string} id Идентификатор direction
     * @param {DirectionUpdateDirectionRequest} direction Данные для обновления direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsApi
     */
    public apiOccupancyDirectionIdPut(id: string, direction: DirectionUpdateDirectionRequest, options?: AxiosRequestConfig) {
        return DirectionsApiFp(this.configuration).apiOccupancyDirectionIdPut(id, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание direction.
     * @summary создание direction.
     * @param {DirectionCreateDirectionRequest} direction Данные direction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsApi
     */
    public apiOccupancyDirectionPost(direction: DirectionCreateDirectionRequest, options?: AxiosRequestConfig) {
        return DirectionsApiFp(this.configuration).apiOccupancyDirectionPost(direction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * сформировать отчет.
         * @summary сфоримровать отчет.
         * @param {string} from Дата от
         * @param {string} to Дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyReportFromToGet: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('apiOccupancyReportFromToGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('apiOccupancyReportFromToGet', 'to', to)
            const localVarPath = `/api/occupancy/report/{from}/{to}`
                .replace(`{${"from"}}`, encodeURIComponent(String(from)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyReportFromToXlsxGet: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('apiOccupancyReportFromToXlsxGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('apiOccupancyReportFromToXlsxGet', 'to', to)
            const localVarPath = `/api/occupancy/report/{from}/{to}/xlsx`
                .replace(`{${"from"}}`, encodeURIComponent(String(from)))
                .replace(`{${"to"}}`, encodeURIComponent(String(to)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * сформировать текущий отчет.
         * @summary сфоримровать текущий отчет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyReportLiveGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/occupancy/report/live`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * сформировать отчет.
         * @summary сфоримровать отчет.
         * @param {string} from Дата от
         * @param {string} to Дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyReportFromToGet(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<ReportReportResponse>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyReportFromToGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyReportFromToXlsxGet(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyReportFromToXlsxGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * сформировать текущий отчет.
         * @summary сфоримровать текущий отчет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyReportLiveGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<ReportReportResponse>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyReportLiveGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * сформировать отчет.
         * @summary сфоримровать отчет.
         * @param {string} from Дата от
         * @param {string} to Дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyReportFromToGet(from: string, to: string, options?: any): AxiosPromise<{ [key: string]: Array<ReportReportResponse>; }> {
            return localVarFp.apiOccupancyReportFromToGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * сформировать отчет.
         * @summary сформировать отчет.
         * @param {string} from дата от
         * @param {string} to дата по
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyReportFromToXlsxGet(from: string, to: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOccupancyReportFromToXlsxGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * сформировать текущий отчет.
         * @summary сфоримровать текущий отчет.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyReportLiveGet(options?: any): AxiosPromise<{ [key: string]: Array<ReportReportResponse>; }> {
            return localVarFp.apiOccupancyReportLiveGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * сформировать отчет.
     * @summary сфоримровать отчет.
     * @param {string} from Дата от
     * @param {string} to Дата по
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiOccupancyReportFromToGet(from: string, to: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiOccupancyReportFromToGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * сформировать отчет.
     * @summary сформировать отчет.
     * @param {string} from дата от
     * @param {string} to дата по
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiOccupancyReportFromToXlsxGet(from: string, to: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiOccupancyReportFromToXlsxGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * сформировать текущий отчет.
     * @summary сфоримровать текущий отчет.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiOccupancyReportLiveGet(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiOccupancyReportLiveGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить settings.
         * @summary получить settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancySettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/occupancy/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание settings.
         * @summary создание settings.
         * @param {SettingsCreateSettingsRequest} settings Данные settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancySettingsPost: async (settings: SettingsCreateSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settings' is not null or undefined
            assertParamExists('apiOccupancySettingsPost', 'settings', settings)
            const localVarPath = `/api/occupancy/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * получить settings.
         * @summary получить settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancySettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancySettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание settings.
         * @summary создание settings.
         * @param {SettingsCreateSettingsRequest} settings Данные settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancySettingsPost(settings: SettingsCreateSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettingsSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancySettingsPost(settings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * получить settings.
         * @summary получить settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancySettingsGet(options?: any): AxiosPromise<SettingsSettingsResponse> {
            return localVarFp.apiOccupancySettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * создание settings.
         * @summary создание settings.
         * @param {SettingsCreateSettingsRequest} settings Данные settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancySettingsPost(settings: SettingsCreateSettingsRequest, options?: any): AxiosPromise<SettingsSettingsResponse> {
            return localVarFp.apiOccupancySettingsPost(settings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * получить settings.
     * @summary получить settings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiOccupancySettingsGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiOccupancySettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание settings.
     * @summary создание settings.
     * @param {SettingsCreateSettingsRequest} settings Данные settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiOccupancySettingsPost(settings: SettingsCreateSettingsRequest, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiOccupancySettingsPost(settings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskSettingssApi - axios parameter creator
 * @export
 */
export const TaskSettingssApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех taskSettings.
         * @summary получить список всех taskSettings.
         * @param {string} sort Строка сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsGet: async (sort: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('apiOccupancyTaskSettingsGet', 'sort', sort)
            const localVarPath = `/api/occupancy/task_settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить taskSettings
         * @summary удалить taskSettings
         * @param {string} id Идентификатор taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyTaskSettingsIdDelete', 'id', id)
            const localVarPath = `/api/occupancy/task_settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить taskSettings по id.
         * @summary получить taskSettings по id.
         * @param {string} id Идентификатор taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyTaskSettingsIdGet', 'id', id)
            const localVarPath = `/api/occupancy/task_settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление taskSettings.
         * @summary обновление taskSettings.
         * @param {string} id Идентификатор taskSettings
         * @param {TaskSettingsUpdateTaskSettingsRequest} taskSettings Данные для обновления taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsIdPut: async (id: string, taskSettings: TaskSettingsUpdateTaskSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyTaskSettingsIdPut', 'id', id)
            // verify required parameter 'taskSettings' is not null or undefined
            assertParamExists('apiOccupancyTaskSettingsIdPut', 'taskSettings', taskSettings)
            const localVarPath = `/api/occupancy/task_settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить названия ненастроенных задач.
         * @summary получить названия ненастроенных задач.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsNotConfiguredGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/occupancy/task_settings/not_configured`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание taskSettings.
         * @summary создание taskSettings.
         * @param {TaskSettingsCreateTaskSettingsRequest} taskSettings Данные taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsPost: async (taskSettings: TaskSettingsCreateTaskSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskSettings' is not null or undefined
            assertParamExists('apiOccupancyTaskSettingsPost', 'taskSettings', taskSettings)
            const localVarPath = `/api/occupancy/task_settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskSettingssApi - functional programming interface
 * @export
 */
export const TaskSettingssApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskSettingssApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех taskSettings.
         * @summary получить список всех taskSettings.
         * @param {string} sort Строка сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyTaskSettingsGet(sort: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskSettingsTaskSettingsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyTaskSettingsGet(sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить taskSettings
         * @summary удалить taskSettings
         * @param {string} id Идентификатор taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyTaskSettingsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSettingsTaskSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyTaskSettingsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить taskSettings по id.
         * @summary получить taskSettings по id.
         * @param {string} id Идентификатор taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyTaskSettingsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSettingsTaskSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyTaskSettingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление taskSettings.
         * @summary обновление taskSettings.
         * @param {string} id Идентификатор taskSettings
         * @param {TaskSettingsUpdateTaskSettingsRequest} taskSettings Данные для обновления taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyTaskSettingsIdPut(id: string, taskSettings: TaskSettingsUpdateTaskSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSettingsTaskSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyTaskSettingsIdPut(id, taskSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить названия ненастроенных задач.
         * @summary получить названия ненастроенных задач.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyTaskSettingsNotConfiguredGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyTaskSettingsNotConfiguredGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание taskSettings.
         * @summary создание taskSettings.
         * @param {TaskSettingsCreateTaskSettingsRequest} taskSettings Данные taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyTaskSettingsPost(taskSettings: TaskSettingsCreateTaskSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskSettingsTaskSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyTaskSettingsPost(taskSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskSettingssApi - factory interface
 * @export
 */
export const TaskSettingssApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskSettingssApiFp(configuration)
    return {
        /**
         * получить список всех taskSettings.
         * @summary получить список всех taskSettings.
         * @param {string} sort Строка сортировки
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsGet(sort: string, options?: any): AxiosPromise<Array<TaskSettingsTaskSettingsResponse>> {
            return localVarFp.apiOccupancyTaskSettingsGet(sort, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить taskSettings
         * @summary удалить taskSettings
         * @param {string} id Идентификатор taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsIdDelete(id: string, options?: any): AxiosPromise<TaskSettingsTaskSettingsResponse> {
            return localVarFp.apiOccupancyTaskSettingsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить taskSettings по id.
         * @summary получить taskSettings по id.
         * @param {string} id Идентификатор taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsIdGet(id: string, options?: any): AxiosPromise<TaskSettingsTaskSettingsResponse> {
            return localVarFp.apiOccupancyTaskSettingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление taskSettings.
         * @summary обновление taskSettings.
         * @param {string} id Идентификатор taskSettings
         * @param {TaskSettingsUpdateTaskSettingsRequest} taskSettings Данные для обновления taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsIdPut(id: string, taskSettings: TaskSettingsUpdateTaskSettingsRequest, options?: any): AxiosPromise<TaskSettingsTaskSettingsResponse> {
            return localVarFp.apiOccupancyTaskSettingsIdPut(id, taskSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * получить названия ненастроенных задач.
         * @summary получить названия ненастроенных задач.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsNotConfiguredGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiOccupancyTaskSettingsNotConfiguredGet(options).then((request) => request(axios, basePath));
        },
        /**
         * создание taskSettings.
         * @summary создание taskSettings.
         * @param {TaskSettingsCreateTaskSettingsRequest} taskSettings Данные taskSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyTaskSettingsPost(taskSettings: TaskSettingsCreateTaskSettingsRequest, options?: any): AxiosPromise<TaskSettingsTaskSettingsResponse> {
            return localVarFp.apiOccupancyTaskSettingsPost(taskSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskSettingssApi - object-oriented interface
 * @export
 * @class TaskSettingssApi
 * @extends {BaseAPI}
 */
export class TaskSettingssApi extends BaseAPI {
    /**
     * получить список всех taskSettings.
     * @summary получить список всех taskSettings.
     * @param {string} sort Строка сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskSettingssApi
     */
    public apiOccupancyTaskSettingsGet(sort: string, options?: AxiosRequestConfig) {
        return TaskSettingssApiFp(this.configuration).apiOccupancyTaskSettingsGet(sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить taskSettings
     * @summary удалить taskSettings
     * @param {string} id Идентификатор taskSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskSettingssApi
     */
    public apiOccupancyTaskSettingsIdDelete(id: string, options?: AxiosRequestConfig) {
        return TaskSettingssApiFp(this.configuration).apiOccupancyTaskSettingsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить taskSettings по id.
     * @summary получить taskSettings по id.
     * @param {string} id Идентификатор taskSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskSettingssApi
     */
    public apiOccupancyTaskSettingsIdGet(id: string, options?: AxiosRequestConfig) {
        return TaskSettingssApiFp(this.configuration).apiOccupancyTaskSettingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление taskSettings.
     * @summary обновление taskSettings.
     * @param {string} id Идентификатор taskSettings
     * @param {TaskSettingsUpdateTaskSettingsRequest} taskSettings Данные для обновления taskSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskSettingssApi
     */
    public apiOccupancyTaskSettingsIdPut(id: string, taskSettings: TaskSettingsUpdateTaskSettingsRequest, options?: AxiosRequestConfig) {
        return TaskSettingssApiFp(this.configuration).apiOccupancyTaskSettingsIdPut(id, taskSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить названия ненастроенных задач.
     * @summary получить названия ненастроенных задач.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskSettingssApi
     */
    public apiOccupancyTaskSettingsNotConfiguredGet(options?: AxiosRequestConfig) {
        return TaskSettingssApiFp(this.configuration).apiOccupancyTaskSettingsNotConfiguredGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание taskSettings.
     * @summary создание taskSettings.
     * @param {TaskSettingsCreateTaskSettingsRequest} taskSettings Данные taskSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskSettingssApi
     */
    public apiOccupancyTaskSettingsPost(taskSettings: TaskSettingsCreateTaskSettingsRequest, options?: AxiosRequestConfig) {
        return TaskSettingssApiFp(this.configuration).apiOccupancyTaskSettingsPost(taskSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdatesApi - axios parameter creator
 * @export
 */
export const UpdatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * callback от октелл.
         * @summary callback от октелл.
         * @param {UpdateOktellResultCallback} update Данные для обновления update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateCallbackPost: async (update: UpdateOktellResultCallback, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'update' is not null or undefined
            assertParamExists('apiOccupancyUpdateCallbackPost', 'update', update)
            const localVarPath = `/api/occupancy/update/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(update, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список всех update.
         * @summary получить список всех update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/occupancy/update/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить update
         * @summary удалить update
         * @param {string} id Идентификатор update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyUpdateIdDelete', 'id', id)
            const localVarPath = `/api/occupancy/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить update по id.
         * @summary получить update по id.
         * @param {string} id Идентификатор update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyUpdateIdGet', 'id', id)
            const localVarPath = `/api/occupancy/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление update.
         * @summary обновление update.
         * @param {string} id Идентификатор update
         * @param {UpdateUpdateUpdateRequest} update Данные для обновления update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateIdPut: async (id: string, update: UpdateUpdateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOccupancyUpdateIdPut', 'id', id)
            // verify required parameter 'update' is not null or undefined
            assertParamExists('apiOccupancyUpdateIdPut', 'update', update)
            const localVarPath = `/api/occupancy/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(update, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание update.
         * @summary создание update.
         * @param {UpdateCreateUpdateRequest} update Данные update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdatePost: async (update: UpdateCreateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'update' is not null or undefined
            assertParamExists('apiOccupancyUpdatePost', 'update', update)
            const localVarPath = `/api/occupancy/update/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(update, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdatesApi - functional programming interface
 * @export
 */
export const UpdatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdatesApiAxiosParamCreator(configuration)
    return {
        /**
         * callback от октелл.
         * @summary callback от октелл.
         * @param {UpdateOktellResultCallback} update Данные для обновления update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyUpdateCallbackPost(update: UpdateOktellResultCallback, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyUpdateCallbackPost(update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список всех update.
         * @summary получить список всех update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyUpdateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UpdateUpdateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyUpdateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить update
         * @summary удалить update
         * @param {string} id Идентификатор update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyUpdateIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyUpdateIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить update по id.
         * @summary получить update по id.
         * @param {string} id Идентификатор update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyUpdateIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyUpdateIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление update.
         * @summary обновление update.
         * @param {string} id Идентификатор update
         * @param {UpdateUpdateUpdateRequest} update Данные для обновления update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyUpdateIdPut(id: string, update: UpdateUpdateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyUpdateIdPut(id, update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание update.
         * @summary создание update.
         * @param {UpdateCreateUpdateRequest} update Данные update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOccupancyUpdatePost(update: UpdateCreateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOccupancyUpdatePost(update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdatesApi - factory interface
 * @export
 */
export const UpdatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdatesApiFp(configuration)
    return {
        /**
         * callback от октелл.
         * @summary callback от октелл.
         * @param {UpdateOktellResultCallback} update Данные для обновления update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateCallbackPost(update: UpdateOktellResultCallback, options?: any): AxiosPromise<void> {
            return localVarFp.apiOccupancyUpdateCallbackPost(update, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список всех update.
         * @summary получить список всех update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateGet(options?: any): AxiosPromise<Array<UpdateUpdateResponse>> {
            return localVarFp.apiOccupancyUpdateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить update
         * @summary удалить update
         * @param {string} id Идентификатор update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateIdDelete(id: string, options?: any): AxiosPromise<UpdateUpdateResponse> {
            return localVarFp.apiOccupancyUpdateIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить update по id.
         * @summary получить update по id.
         * @param {string} id Идентификатор update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateIdGet(id: string, options?: any): AxiosPromise<UpdateUpdateResponse> {
            return localVarFp.apiOccupancyUpdateIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление update.
         * @summary обновление update.
         * @param {string} id Идентификатор update
         * @param {UpdateUpdateUpdateRequest} update Данные для обновления update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdateIdPut(id: string, update: UpdateUpdateUpdateRequest, options?: any): AxiosPromise<UpdateUpdateResponse> {
            return localVarFp.apiOccupancyUpdateIdPut(id, update, options).then((request) => request(axios, basePath));
        },
        /**
         * создание update.
         * @summary создание update.
         * @param {UpdateCreateUpdateRequest} update Данные update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOccupancyUpdatePost(update: UpdateCreateUpdateRequest, options?: any): AxiosPromise<UpdateUpdateResponse> {
            return localVarFp.apiOccupancyUpdatePost(update, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdatesApi - object-oriented interface
 * @export
 * @class UpdatesApi
 * @extends {BaseAPI}
 */
export class UpdatesApi extends BaseAPI {
    /**
     * callback от октелл.
     * @summary callback от октелл.
     * @param {UpdateOktellResultCallback} update Данные для обновления update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdatesApi
     */
    public apiOccupancyUpdateCallbackPost(update: UpdateOktellResultCallback, options?: AxiosRequestConfig) {
        return UpdatesApiFp(this.configuration).apiOccupancyUpdateCallbackPost(update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список всех update.
     * @summary получить список всех update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdatesApi
     */
    public apiOccupancyUpdateGet(options?: AxiosRequestConfig) {
        return UpdatesApiFp(this.configuration).apiOccupancyUpdateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить update
     * @summary удалить update
     * @param {string} id Идентификатор update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdatesApi
     */
    public apiOccupancyUpdateIdDelete(id: string, options?: AxiosRequestConfig) {
        return UpdatesApiFp(this.configuration).apiOccupancyUpdateIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить update по id.
     * @summary получить update по id.
     * @param {string} id Идентификатор update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdatesApi
     */
    public apiOccupancyUpdateIdGet(id: string, options?: AxiosRequestConfig) {
        return UpdatesApiFp(this.configuration).apiOccupancyUpdateIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление update.
     * @summary обновление update.
     * @param {string} id Идентификатор update
     * @param {UpdateUpdateUpdateRequest} update Данные для обновления update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdatesApi
     */
    public apiOccupancyUpdateIdPut(id: string, update: UpdateUpdateUpdateRequest, options?: AxiosRequestConfig) {
        return UpdatesApiFp(this.configuration).apiOccupancyUpdateIdPut(id, update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание update.
     * @summary создание update.
     * @param {UpdateCreateUpdateRequest} update Данные update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdatesApi
     */
    public apiOccupancyUpdatePost(update: UpdateCreateUpdateRequest, options?: AxiosRequestConfig) {
        return UpdatesApiFp(this.configuration).apiOccupancyUpdatePost(update, options).then((request) => request(this.axios, this.basePath));
    }
}


