import { Trash } from "tabler-icons-react";
import { Field } from "./form";
import { createRef } from "react";

export const RangeValueMapField = (props: { index: number, field: Field, value: { [key: string]: number; }, updateForm: (name: string, value: any) => void }) => {
    let ref = createRef<HTMLInputElement>();

    const deleteField = (fieldName: string) => {
        let newFieldValue = props.field.value;
        delete newFieldValue[fieldName];
        props.updateForm(props.field.name, newFieldValue)
    }

    const updateFieldValue = (fieldName: string, value: any) => {
        let newFieldValue = props.field.value;
        newFieldValue[fieldName] = value;
        props.updateForm(props.field.name, newFieldValue)
    }

    const getSortedKeys = () => {
        let keys: number[] = [];
        Object.keys(props.value).map((key) => {
            keys.push(parseInt(key))
        })

        return keys;
    }

    const addSection = () => {
        if (ref) {
            let value = parseInt(ref.current?.value!);
            if (value > 0 && !getSortedKeys().includes(value)) {
                let newFieldValue = props.field.value;
                newFieldValue[value] = 0;
                props.updateForm(props.field.name, newFieldValue)
            }
        }
    }
    
    return <div className="row mb-5" style={{ display: "flex", flexDirection: "row" }} >
        <div className="col-lg-6">
            <div className="demo-inline-spacing mt-3 mb-3">
                <span className="form-label">{props.field.title}</span>
                <div className="list-group">
                    {getSortedKeys().map((key, index) => {
                        return <a href="#" className="list-group-item list-group-item-action">
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                                <table className="table-responsive text-nowrap" >
                                    <thead style={{ width: "100%" }}>
                                        <tr>
                                            <td style={{ width: "15%" }}>{index == 0 ? "0" : (parseInt(Object.keys(props.value)[index - 1]) + 1)} - {key} % </td>
                                            <td><input className="form-control" type="number" onChange={(e)=>{updateFieldValue(key.toString(), parseInt(e.target.value))}} value={props.value[key]} /></td>
                                            {key != 100 ?<td style={{ width: "5%" }}><Trash color="red" onClick={()=> {deleteField(key.toString())}} /></td>:<></>}
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </a>
                    })}
                </div>
            </div>
        </div>
        <div className="col-lg-6" style={{display: "flex", alignItems: "end"}}>
            <div className="d-flex justify-content-end">
                <input ref={ref} className="form-control" type="text" style={{ marginRight: "10px" }} />
                <button key="submit" onClick={(e) => {e.preventDefault(); addSection()}} className="btn btn-primary d-grid w-100">Добавить секцию</button>
            </div>
        </div>
    </div>
}