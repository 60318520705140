export enum CallActionTypes {
    CALL_REQUEST_SEND = 'call_request_send',
    CALL_ERROR = 'call_error',
    CALL_SUCCESS = 'call_success',
    
    CALL_CREATE_REQUEST = 'call_create_request',
    CALL_CREATE_SUCCESS = 'call_create_success',
    CALL_CREATE_ERROR = 'call_create_error',

    CALL_DELETE_REQUEST = 'call_delete_request',
    CALL_DELETE_SUCCESS = 'call_delete_success',
    CALL_DELETE_ERROR = 'call_delete_error',

    CALL_UPDATE_REQUEST = 'call_update_request',
    CALL_UPDATE_SUCCESS = 'call_update_success',
    CALL_UPDATE_ERROR = 'call_update_error',
}