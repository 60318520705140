import { useContext, useState } from "react"
import { PlayCircle } from "react-feather"
import { UseCasesContext } from "../../../../context/useCases"
import { Download } from "tabler-icons-react"

export const AudioLink = (props: { url: string, leadId: string }) => {
    const [localLink, setLocalLink] = useState<string>("")

    let useCases = useContext(UseCasesContext)

    const downloadLink = async () => {
        let response = await useCases?.callUseCase.GetAudioLocalLink(props.url)
        if (response instanceof Error) {

        } else {
            setLocalLink(response!)
        }
    }

    return (
        localLink === "" ? <button type="button" onClick={() => downloadLink()} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false"><PlayCircle /></ button> : <div style={{display: "flex", alignItems: "center"}} >
            <audio controls>
                <source src={"/api/audio/" + localLink} type="audio/mpeg" />
            </audio>
            <span style={{marginLeft:"5px"}}><a href={"/api/audio/" + localLink} download={"call_"+props.leadId + ".mp3"} target="_blank"><Download /></a></span>
        </div>
    )
}